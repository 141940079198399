import React, { useState } from "react";
import {
  Modal,
  Button,
  Form,
  CardTitle,
  FormGroup,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  Input,
  CardImg,
} from "reactstrap";
import { useDispatch } from "react-redux";
import { updateUserInfoRequest } from "store/actions";
import { Spinner } from "reactstrap";
import { toast } from "react-toastify";

const UserModal = ({ user, closeModal, handleUpdateSuccess }) => {
  const [editedUser, setEditedUser] = useState(user);
  const [validationErrors, setValidationErrors] = useState({});
  const [img, setImg] = useState(user.profile_img_url);
  const [isEdited, setIsEdited] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false); // Track form submission
  const dispatch = useDispatch();
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedUser({ ...editedUser, [name]: value });
    setIsEdited(true);
  };




  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    const MAX_FILE_SIZE = 1 * 1024 * 1024; // 1MB in bytes
    if (file) {
      if (isFileValid(file)) {
        if (file.size <= MAX_FILE_SIZE) {

          const imageUrl = URL.createObjectURL(file);
          setImg(imageUrl);
          setEditedUser({ ...editedUser, img: file });
          setIsEdited(true);
          toast.success("تم تحميل الصورة بنجاح!", {
            position: "top-right",
            autoClose: 3000,
          });
        } else {
          toast.error("ألا تتجاوز الصورة الحد الأقصى المسموح به (1 ميجابايت). الرجاء اختيار ملف أصغر.", {
            position: "top-right",
            autoClose: 5000,
          });
        }
      } else {

        toast.error("نوع الملف غير صالح. يرجى تحميل صورة.", {
          position: "top-right",
          autoClose: 5000,
        });
      }
    }
  };

  const isFileValid = (file) => {
    const allowedFileTypes = ["image/jpeg", "image/png", "image/gif", "image/jpg"];
    return allowedFileTypes.includes(file.type);
  };




  const handleSubmit = async () => {
    // Trim all text fields before submitting
    const trimmedUserData = Object.keys(editedUser).reduce((acc, key) => {
      if (typeof editedUser[key] === 'string') {
        acc[key] = editedUser[key].trim(); // Trim the value if it's a string
      } else {
        acc[key] = editedUser[key]; // Keep the value as is for non-string fields
      }
      return acc;
    }, {});
  
    // Validate form fields with trimmed values
    const validationErrors = validateFormFields(trimmedUserData);
    if (Object.keys(validationErrors).length > 0) {
      setValidationErrors(validationErrors);
      return;
    }
  
    if (isEdited) {
      setIsSubmitting(true);
      const { documentId, ...userData } = trimmedUserData;
      const updatedData = { documentId, ...trimmedUserData };
      try {
        await dispatch(updateUserInfoRequest(updatedData)); // Use async/await for dispatch
        setTimeout(() => {
          closeModal();
        }, 1000); // 1 second delay
      } catch (error) {
        // Handle error if needed (e.g., display an error message)
      } finally {
        setIsSubmitting(false); // Reset isSubmitting state after update (success or error)
      }
    }
  };
  

  const validateFormFields = () => {
    const errors = {};
    if (!editedUser.full_name) errors.full_name = "يجب إدخال اسم الثلاثي.";
    if (!editedUser.salon_name) errors.salon_name = "يجب إدخال اسم الفرع.";
    if (!editedUser.location) errors.location = "يجب إدخال عنوان الفرع.";
    if (!editedUser.bank_account) errors.bank_account = "يجب إدخال حساب البنكي.";
    if (!editedUser.account_name) errors.account_name = "يجب إدخال اسم البنك.";
    if (!editedUser.description) errors.description = "يجب إدخال وصف.";
    if (!editedUser.mobile) errors.mobile = "يجب إدخال رقم الهاتف.";

    return errors;
  };

  const closebutton = () => {
    closeModal();
  };

  return (
    <Modal isOpen={true} role="dialog" autoFocus={true} centered={true} className="exampleModal" tabIndex="-1">
      <ModalHeader onClick={closebutton}>
        <CardTitle>تعديل بيانات الشخصية</CardTitle>
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup controlid="full_name">
            <Label>اسم الثلاثي</Label>
            <Input type="text" name="full_name" value={editedUser.full_name} onChange={handleInputChange} />
            {validationErrors.full_name && <div className="text-danger">{validationErrors.full_name}</div>}
          </FormGroup>

          <FormGroup controlid="location">
            <Label>عنوان الفرع</Label>
            <Input type="text" name="location" value={editedUser.location} onChange={handleInputChange} />
            {validationErrors.location && <div className="text-danger">{validationErrors.location}</div>}
          </FormGroup>

          <FormGroup controlid="salon_name">
            <Label> اسم الفرع </Label>
            <Input type="text" name="salon_name" value={editedUser.salon_name} onChange={handleInputChange} />
            {validationErrors.salon_name && <div className="text-danger">{validationErrors.salon_name}</div>}
          </FormGroup>

          <FormGroup controlid="mobile">
            <Label>  رقم الهاتف </Label>
            <Input type="number" name="mobile" value={editedUser.mobile} onChange={handleInputChange} />
            {validationErrors.mobile && <div className="text-danger">{validationErrors.mobile}</div>}

          </FormGroup>

          <FormGroup controlid="account_name">
            <Label>اسم البنك</Label>
            <Input type="text" name="account_name" value={editedUser.account_name} onChange={handleInputChange} />
            {validationErrors.account_name && <div className="text-danger">{validationErrors.account_name}</div>}
          </FormGroup>

          <FormGroup controlid="bank_account">
            <Label>حساب البنكي</Label>
            <Input type="text" name="bank_account" value={editedUser.bank_account} onChange={handleInputChange} />
            {validationErrors.bank_account && <div className="text-danger">{validationErrors.bank_account}</div>}
          </FormGroup>

          <FormGroup controlid="description">
            <Label>وصف</Label>
            <Input type="textarea" name="description" value={editedUser.description} onChange={handleInputChange} />
            {validationErrors.description && <div className="text-danger">{validationErrors.description}</div>}
          </FormGroup>



          <FormGroup controlid="img">
            <Label>صورة</Label>
            <Input type="file" name="img" accept="image/*" onChange={handleImageChange} />
            {img && <CardImg src={img} alt="Profile" className="img-thumbnail" style={{ maxWidth: "100px", maxHeight: "100px" }} />}
          </FormGroup>
        </Form>
      </ModalBody>

      <ModalFooter>
        <Button variant="secondary" onClick={closebutton}>
          إلغاء
        </Button>
        <Button variant="primary" disabled={!isEdited || isSubmitting} onClick={handleSubmit}>
          {isSubmitting ? <Spinner size="sm" /> : "حفظ التغييرات"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default UserModal;
