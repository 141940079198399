import Cookies from "js-cookie"



import * as actionTypes from "./actionTypes";

const initialState = {
  error: "",
  loading: false,
  userId: Cookies.get("usersessionId", { secure: true, sameSite: "strict", path: "/" }) || null,
  usersessionId: Cookies.get("usersessionId", { secure: true, sameSite: "strict", path: "/" }) || null,
  user: null,
  emailChangeError: null,
  passwordChangeError: null,
  emailChangeSucess: false,
  passwordChangeSucess: false,
  rates: [],
  notifications: [], // New state for notifications
}

const login = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_USER:
      state = {
        ...state,
        loading: true,
      }
      break

    case actionTypes.RESET_USER_INFO:
      return {
        ...state,
        user: null,
      }

    case actionTypes.LOGIN_SUCCESS:
      Cookies.set("usersessionId", action.payload.userId, { secure: true, sameSite: "strict", path: "/" })
      Cookies.set("usersessionId", action.payload.$id, { secure: true, sameSite: "strict", path: "/" })
      return {
        ...state,
        userId: action.payload.userId,
        usersessionId: action.payload.$id,
      }
    case actionTypes.STORE_USER_INFO:
      return {
        ...state,
        user: action.payload,
      }
      case actionTypes.STORE_USER_RATES:
        return {
          ...state,
          rates: action.payload,
        }

      
    case actionTypes.UPDATE_USER_INFO_SUCCESS:
      return {
        ...state,
        user: action.payload,
      }

    case actionTypes.LOGOUT_USER:
      state = { ...state }
      break
    case actionTypes.LOGOUT_USER_SUCCESS:
      state = { ...state, isUserLogout: true }
      break
    case actionTypes.API_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        isUserLogout: false,
      }
      break
    case actionTypes.LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

      case actionTypes.CHANGE_PASSWORD_SUCCESS:
        // Handle password change success
        return { ...state,
          passwordChangeError: null,
          passwordChangeSucess: true,
        };
  
      case actionTypes.CHANGE_EMAIL_SUCCESS:
        // Handle email change success
        return { ...state,
          emailChangeError: null,
          emailChangeSucess: true,
        };


        case actionTypes.CHANGE_EMAIL_FAILURE:
          return {
            ...state,
            // Update state with the error message
            emailChangeError: action.error?.message || ' يرجى التحقق من عنوان بريدك الإلكتروني او كلمة المرور', // Use the error message if available
          };

          case actionTypes.CHANGE_PASSWORD_FAILURE:
            return {
              ...state,
              // Update state with the error message
              passwordChangeError:  action.error?.message || 'كلمة المرور غير متطابقة', // Use the error message if available
            };



          case actionTypes.CLEAR_EMAIL_CHANGE_ERROR:
            return {
              ...state,
              emailChangeError: null, // Clear the email change error
            };

            case actionTypes.CLEAR_PASSWORD_CHANGE_ERROR:
            return {
              ...state,
              passwordChangeError: null, // Clear the email change error
            };


            case actionTypes.CLEAR_EMAIL_CHANGE_SUCCESS:
              return {
                ...state,
                emailChangeSucess: false,
              };
        
            case actionTypes.CLEAR_PASSWORD_CHANGE_SUCCESS:
              return {
                ...state,
                passwordChangeSucess: false,
            
              };

              case actionTypes.FETCH_NOTIFICATIONS_SUCCESS:
                return { ...state, notifications: action.payload }; // Store notifications in the state
          
              case actionTypes.FETCH_NOTIFICATIONS_FAILURE:
                return { ...state, error: action.payload };


    default:
      state = { ...state }
      break
  }
  return state
}

export default login
