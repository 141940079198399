import { databases } from "helpers/appwrite/appwriteConfig";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  FETCH_ORDERS_REQUEST,
  fetchOrdersSuccess,
  fetchOrdersFailure,
  updateOrderStatusSuccess,
  updateOrderStatusFailure,
  UPDATE_ORDER_STATUS_REQUEST,
} from "./actions";
import appwritPointes from "helpers/appwrite/appwritePointes";
import Cookies from "js-cookie";
import { Query, ID } from "appwrite";
import axios from 'axios';



async function fetchOrdersFromAPI() {
  // Correct the function name
  const provider_id = Cookies.get("usersessionId", {
    secure: true,
    sameSite: "strict",
    path: "/",
  })
  try {
    const response = await databases.listDocuments(
      appwritPointes.databaseID, // Replace with your collection ID
      appwritPointes.ordersID, // Replace with your document ID
      [Query.equal(["service_providers_id"], [provider_id])],
    )
    return response
  } catch (error) {
    throw error
  }
}


async function appNotification(notificationData, deviceToken) {
  const API_URL = 'https://node-server-olive.vercel.app/send-notification';
  const title = notificationData.title;
  const body = notificationData.content;

  try {
    // Attempt to send the notification to the external API
    if (deviceToken) {
      try {
        await axios.post(API_URL, {
          deviceToken,
          title,
          body
        }, {
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_NODE_API_KEY // API key for platform1
          }
        });
      } catch (apiError) {
        // console.error('Failed to send notification to external API:', apiError.message);
        // Optionally, handle the error (e.g., show a message, log it, etc.)
      }
    }

    // Attempt to create the notification document in Appwrite
    const response = await databases.createDocument(
      appwritPointes.databaseID,
      appwritPointes.appnotificationID,
      ID.unique(),
      notificationData
    );

    return response;
  } catch (error) {
    // console.error('Error creating notification in Appwrite:', error.message);
    // Handle the error gracefully
    return null; // or return some fallback value
  }
}

// Define a function to update order status
async function updateOrderStatusInAPI(orderId, newStatus) {
  try {
    const response = await databases.updateDocument(
      appwritPointes.databaseID,
      appwritPointes.ordersID,
      orderId,
      { booking_status: newStatus }
    );

    return response;
  } catch (error) {
    throw error;
  }
}

function* fetchOrders() {
  try {
    // Make an API call to fetch orders using Appwrite
    const response = yield call(fetchOrdersFromAPI);
    // Dispatch success action with the fetched orders
    yield put(fetchOrdersSuccess(response.documents));
  } catch (error) {
    // Dispatch failure action if there's an error
    yield put(fetchOrdersFailure(error));
  }
}

function* updateOrderStatus({ payload }) {
  try {
    // Make an API request to update the order status
    const response = yield call(
      updateOrderStatusInAPI,
      payload.orderId,
      payload.newStatus
    );
    if (response) {
     
      const getType = (type) => {
      switch (type) {
        case "approved":
        return "تم الموافقة على الطلب";
        case "pending":
        return "قيد الانتظار";
        case "completed":
        return "مكتمل";
        default:
        return "/";
      }
      };
      const notificationData = {
      title: "تحديث حالة الحجز",
      content: `تم تحديث حالة الحجز  ${response.serviceProviderServices.name} إلى ${getType(payload.newStatus)}`,
      notification_type: "BOOKING",
      orderId: response.$id,
      isSeen: false,
      created_at: new Date().toISOString(),
      appUser: response.app_user_id.$id,
      };
      const token = response.app_user_id.notification_token
      
      yield call(appNotification, notificationData, token);
    }

    // Dispatch success action with the updated data
    yield put(updateOrderStatusSuccess(response));
  } catch (error) {
    // Dispatch failure action with the error
    yield put(updateOrderStatusFailure(error));
  }
}

// Watch for FETCH_ORDERS_REQUEST action and trigger fetchOrders saga
function* watchFetchOrders() {
  yield takeLatest(FETCH_ORDERS_REQUEST, fetchOrders);
  yield takeLatest(UPDATE_ORDER_STATUS_REQUEST, updateOrderStatus);
}

export default watchFetchOrders;
