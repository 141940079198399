import React from "react";
import ReactApexChart from "react-apexcharts";
import "./dashboard.scss";
import getChartColorsArray from "../../components/Common/ChartsDynamicColor";

const ApexRadial = ({ dataColors, dailyProfits }) => {
  const apexRadialChartColors = getChartColorsArray(dataColors);


  // Calculate total profit
  const totalProfit = dailyProfits.reduce((acc, curr) => acc + curr.total, 0);



  // Assuming a goal of 1000 for simplicity, calculate percentage
  const goal = 1000; // This can be dynamic based on your requirements
  const profitPercentage = Math.min((totalProfit / goal) * 100, 100); // Ensuring it doesn't exceed 100%

  const series = [profitPercentage.toFixed(2)];
  const options = {
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 135,
        dataLabels: {
          name: {
            fontSize: "13px",
            color: void 0,
            offsetY: 60,
          },
          value: {
            offsetY: 22,
            fontSize: "16px",
            color: void 0,
            formatter: function (e) {
              return e + "%";
            },
          },
        },
      },
    },
    colors: apexRadialChartColors,
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        shadeIntensity: 0.15,
        inverseColors: !1,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 65, 91],
      },
    },
    stroke: {
      dashArray: 4,
    },
    labels: ["اجمالي الارباح "],
  };
  return (
    <ReactApexChart
      options={options}
      series={series}
      type="radialBar"
      height="200"
      className="apex-charts"
    />
  );
};

export default ApexRadial;