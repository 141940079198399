import React, { useEffect, useState, useMemo } from "react"
import Cookies from "js-cookie"
import { useDispatch, useSelector } from "react-redux"
import {
  fetchUserInfo,
  changeEmailRequest,
  changePasswordRequest,
  clearEmailChangeError,
  clearPasswordChangeError,
  changePasswordFailure,
  clearEmailChangeSuccess,
  clearPasswordChangeSuccess,
  shopStatus
} from "../../store/actions"
import { fetchServicesRequest, fetchOrdersRequest } from "../../store/actions"
import moment from "moment"
import { connect } from "react-redux"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
  Button,
  Modal,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  FormGroup,
  UncontrolledAlert,
} from "reactstrap"
import classnames from "classnames"
import Rating from "react-rating"
import { Link } from "react-router-dom"
import UserModal from "./UserModal"
import TableContainer from "components/Common/TableContainer"
import { Pdate, Ddate, Name, Idno, Budget } from "./AccCol"

import MapsGoogle from "pages/Maps/MapsGoogle"
import { toast } from "react-toastify"
import { toUpper } from "lodash"
const UserProfile = () => {
  document.title = "حساب تعريفي  | DBsaloni"
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modal_center, setmodal_center] = useState(false)
  const dispatch = useDispatch()
  const [activeTab, setactiveTab] = useState("1")
  const [activeTab1, setactiveTab1] = useState("5")
  const [activeTab2, setactiveTab2] = useState("1")
  const [activeTab3, setactiveTab3] = useState("1")
  const {
    passwordChangeError,
    passwordChangeSucess,
    emailChangeError,
    emailChangeSucess,
  } = useSelector(state => state.Login)
  const [loading, setLoading] = useState(false);

  const { user, rates } = useSelector(state => state.Login)
  let totalRate = 0
  if (rates.length) {
    for (let i = 0; i < rates.length; i++) {
      totalRate += rates[i].rating
    }
  }

  const averageRating = rates.length > 0 ? totalRate / rates.length : 0

  const totalCompletedOrders = useSelector(
    state => state.orders.totalCompletedOrders
  )
  const totalPendingOrders = useSelector(
    state => state.orders.totalPendingOrders
  )
  const services = useSelector(state => state.service.services)

  // Component code
  useEffect(() => {
    const usersessionId = Cookies.get("userId", {
      secure: true,
      sameSite: "strict",
      path: "/",
    })
    if (usersessionId) {
      dispatch(fetchUserInfo(usersessionId))
    }
  }, [dispatch])

  useEffect(() => {
    setReports([
      // {
      //   title: "إجمالي الإيرادات",
      //   iconClass: "bx-package",
      //   description: "SAR 36,524",

      // },
      {
        title: "الطلبات المعلقة",
        iconClass: "bx-hourglass",
        description: totalPendingOrders,
      },
      {
        title: "الطلبات المنجزة",
        iconClass: "bx-check-circle",
        description: totalCompletedOrders,

      },
    ])
  }, [totalCompletedOrders, totalPendingOrders])

  useEffect(() => {
    dispatch(fetchServicesRequest())
    dispatch(fetchOrdersRequest())
  }, [dispatch])

  const toggle = tab => {
    if (activeTab !== tab) {
      setactiveTab(tab)
    }
  }

  const toggle1 = tab => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab)
    }
  }

  const toggle2 = tab => {
    if (activeTab2 !== tab) {
      setactiveTab2(tab)
    }
  }

  const toggle3 = tab => {
    if (activeTab3 !== tab) {
      setactiveTab3(tab)
    }
  }

  const tog_center = () => {
    setmodal_center(!modal_center)
    removeBodyCss()
  }

  const removeBodyCss = () => {
    document.body.classList.add("no_padding")
  }

  const openModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const [reports, setReports] = useState([
    {
      title: "الطلبات المنجزة",
      iconClass: "bx-check-circle",
      description: totalCompletedOrders,
    },
    {
      title: "الطلبات المعلقة",
      iconClass: "bx-hourglass",
      description: totalPendingOrders,
    },
    { title: "إيرادات", iconClass: "bx-package", description: "SAR 36,524" },
  ])

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "$id",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => <Idno {...cellProps} />,
      },
      {
        Header: "اسم الخدمة",
        accessor: "name",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => <Name {...cellProps} />,
      },
      {
        Header: " نوع الخدمة	 ",
        accessor: "service_type",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => <Pdate {...cellProps} />,
      },
      {
        Header: "السعر",
        accessor: "service_price",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => <Ddate {...cellProps} />,
      },
      {
        Header: "قسم الخدمة",
        accessor: "service_category",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => <Budget {...cellProps} />,
      },
    ],
    []
  )

  const handleUpdateSuccess = () => {
    const usersessionId = Cookies.get("userId", {
      secure: true,
      sameSite: "strict",
      path: "/",
    })
    dispatch(fetchUserInfo(usersessionId))
  }

  const handleSubmitPassword = async e => {
    e.preventDefault()

    const newPassword = e.target.newPassword.value
    const confirmPassword = e.target.confirmPassword.value

    if (newPassword !== confirmPassword) {
      const error = new Error("كلمة المرور غير متطابقة")
      dispatch(changePasswordFailure(error))
      return
    }

    try {
      dispatch(changePasswordRequest({ newPassword }))
      setmodal_center(false)
    } catch (error) {
      dispatch(changePasswordFailure(error))
    }
  }

  const handleSubmitEmail = async e => {
    e.preventDefault()

    const newEmail = e.target.newEmail.value
    const password = e.target.password.value

    if (!isValidEmail(newEmail)) {
      dispatch(changeEmailFailure(error))
      return
    }

    try {
      const emailAndPasswordData = {
        newEmail,
        password,
      }
      dispatch(changeEmailRequest(emailAndPasswordData))
      setmodal_center(false)
    } catch (error) {
      dispatch(changeEmailFailure(error))
    }
  }

  const isValidEmail = email => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  const handleClearEmailChangeError = () => {
    dispatch(clearEmailChangeError())
  }

  const handleClearPasswordChangeError = () => {
    dispatch(clearPasswordChangeError())
  }

  const handleClearPasswordChangeSucess = () => {
    dispatch(clearPasswordChangeSuccess())
  }

  const handleClearEmailChangeSucess = () => {
    dispatch(clearEmailChangeSuccess())
  }
  if (passwordChangeSucess) {
    toast.success("تم تحديث كلمة المرور بنجاح")
    handleClearPasswordChangeSucess()
  } else if (passwordChangeError) {
    toast.error("حدث خطأ أثناء تحديث كلمة المرور")
    handleClearPasswordChangeError()
  } else if (emailChangeSucess) {
    toast.success("تم تحديث البريد الإلكتروني بنجاح")
    handleClearEmailChangeSucess()

  } else if (emailChangeError) {
    toast.error("حدث خطأ أثناء تحديث البريد الإلكتروني")
    handleClearEmailChangeError()
  }

  const toggleShopStatus = async () => {
    if (user.is_online) {
      // Show toast warning
      toast.warning("جاري إغلاق المتجر، يرجى الانتظار...", {
        position: "top-center",
      });
    } else {
      // Show toast for opening the store
      toast.info("جاري فتح المتجر...", {
        position: "top-center",
      });
    }

    // Set loading state to true
    setLoading(true);

    const newStatus = user.is_online ? false : true;
    await dispatch(shopStatus(newStatus));

    // Set loading state to false after the operation is done
    setLoading(false);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>

          <MapsGoogle />
          <Row>
            <Col xl="4">
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col
                      col="12"
                      style={{
                        backgroundImage: `url(${user && user.profile_img_url
                          ? user.profile_img_url
                          : "./image/noimg.jpg"
                          })`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        width: "100%",
                        height: "35vh",
                        backgroundPosition: "center",
                      }}
                    >
                      <div className="text-primary p-3 text-white "> </div>
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <Row>
                  <Col sm="4">
                  <div className="avatar-md profile-user-wid mb-4"></div>
                  {user && (
                    <>
                      <h5 className="font-size-15 text-truncate">
                        {" "}
                        {user.full_name}
                      </h5>

                      <small className="text-muted mb-0 text-truncate">
                        {user.email}
                      </small>
                      <small className="text-muted mb-0 text-truncate">
                        تاريخ التسجيل :
                        {moment(user.created_at).format("MMM,D YYYY")}
                      </small>
                      <small className="mb-5">  {user.is_online ? "متصل" : "مغلق"}</small>
                      <Button
                      onClick={toggleShopStatus}
                      className={user.is_online ? "btn btn-success btn-sm mt-2" : "btn btn-warning btn-sm mt-2"}
                      disabled={loading} // Disable button when loading
                    >
                      {loading ? "جارٍ التنفيذ..." : user.is_online ? "إغلاق المتجر" : "فتح المتجر"}
                  </Button>
                    </>
                  )}
                </Col>
                    <Col sm={8}>
                      <div className="pt-4">
                        <div className="p-4 text-center mt-4">
                          <h5 className="font-size-15">تقييم العملاء</h5>
                          <Rating
                            stop={5}
                            emptySymbol="mdi mdi-star-outline text-primary "
                            fullSymbol="mdi mdi-star text-primary "
                            className="rating-symbol-background"
                            initialRating={averageRating}
                            readonly
                          />

                          <div className="mt-1">
                            <Button
                              onClick={openModal}
                              className="btn btn-primary btn-sm"
                            >
                              تعديل بيانات الشخصية{" "}
                              <i className="mdi mdi-arrow-right ms-1"></i>
                            </Button>
                          </div>

                          {isModalOpen && (
                            <UserModal
                              user={user}
                              closeModal={closeModal}
                              handleUpdateSuccess={handleUpdateSuccess}
                            />
                          )}
                          <div className="mt-1">
                            <Button
                              className="btn btn-primary btn-sm"
                              onClick={() => {
                                tog_center()
                              }}
                            >
                              تغيير كلمة المرور{" "}
                              <i className="mdi mdi-arrow-right ms-1"></i>
                            </Button>

                            <Modal
                              isOpen={modal_center}
                              toggle={() => {
                                tog_center()
                              }}
                              centered
                            >
                              <div className="modal-header">
                                <button
                                  type="button"
                                  onClick={() => {
                                    setmodal_center(false)
                                  }}
                                  className="close"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                >
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>

                              <Nav tabs>
                                <NavItem>
                                  <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                      active: activeTab === "1",
                                    })}
                                    onClick={() => {
                                      toggle("1")
                                    }}
                                  >
                                    تغيير كلمة المرور
                                  </NavLink>
                                </NavItem>

                              </Nav>

                              <TabContent
                                activeTab={activeTab}
                                className="p-3 text-muted"
                              >
                                <TabPane tabId="1">
                                  <Row>
                                    <Col sm="12">
                                      <form
                                        onSubmit={e => {
                                          e.preventDefault()
                                          const newPasswordInput =
                                            e.target.newPassword.value.trim()
                                          const confirmPasswordInput =
                                            e.target.confirmPassword.value.trim()

                                          if (

                                            newPasswordInput === "" ||
                                            confirmPasswordInput === ""
                                          ) {
                                            alert("يرجى ملء جميع الحقول")
                                            return
                                          }

                                          handleSubmitPassword(e)
                                        }}
                                      >
                                        <FormGroup>
                                          <label htmlFor="newPassword">
                                            كلمة المرور الجديدة:
                                          </label>
                                          <input
                                            type="password"
                                            id="newPassword"
                                            name="newPassword" // Add name attribute
                                            className="form-control"
                                            placeholder="كلمة المرور الجديدة"
                                          />
                                        </FormGroup>
                                        <FormGroup>
                                          <label htmlFor="confirmPassword">
                                            تأكيد كلمة المرور:
                                          </label>
                                          <input
                                            type="password"
                                            id="confirmPassword"
                                            name="confirmPassword" // Add name attribute
                                            className="form-control"
                                            placeholder="تأكيد كلمة المرور"
                                          />
                                        </FormGroup>
                                        <button
                                          type="submit"
                                          className="btn btn-primary"
                                        >
                                          حفظ
                                        </button>
                                      </form>
                                    </Col>
                                  </Row>
                                </TabPane>










                              </TabContent>
                            </Modal>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              {user && (
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">
                      <h2>{toUpper(user.salon_name)}</h2>
                    </CardTitle>
                    <p className="text-muted mb-4">{user.description}</p>
                    <div className="table-responsive">
                      <Table className="table-nowrap mb-0">
                        <tbody>
                          <tr>
                            <th scope="row">اسم الثلاثي :</th>
                            <td>{user.full_name}</td>
                          </tr>
                          <tr>
                            <th scope="row">رقم الهاتف :</th>
                            <td style={{ direction: "ltr" }}>{user.mobile}</td>
                          </tr>
                          <tr>
                            <th scope="row"> بريد إلكتروني :</th>
                            <td>{user.email}</td>
                          </tr>
                          <tr>
                            <th scope="row">عنوان الفرع :</th>
                            <td>{user.location}</td>
                          </tr>
                          <tr>
                            <th scope="row">حساب البنكي :</th>
                            <td>{user.bank_account}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              )}
            </Col>

            <Col xl="8">
              <Row>
                {reports.map((report, key) => (
                  <Col md="4" key={"_col_" + key}>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              {report.title}
                            </p>
                            <h4 className="mb-0">{report.description}</h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={
                                  "bx " + report.iconClass + " font-size-24"
                                }
                              ></i>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>

              <Card>
                <CardBody>
                  <CardTitle className="mb-4">خدماتي</CardTitle>

                  {services.length > 0 ? (
                    <TableContainer
                      columns={columns}
                      data={services || []}
                      isGlobalFilter={false}
                      customPageSize={5}
                      customPageSizeOptions={true}
                    />
                  ) : (
                    <div className="d-grid gap-2 ">
                      <Link
                        to="/servicesManagment"
                        className="btn btn-primary  btn-lg"
                        type="button"
                      >
                        إضافة خدمة جديدة{" "}
                        <i className="mdi mdi-arrow-right ms-1"></i>
                      </Link>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  orders: state.orders.orders,
  user: state.Login.user,
})

export default connect(mapStateToProps)(UserProfile)