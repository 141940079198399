import { useEffect } from "react";
import { useDispatch } from "react-redux";
import client from "helpers/appwrite/appwriteConfig";
import appwritPointes from "helpers/appwrite/appwritePointes";
import Cookies from "js-cookie";
import { fetchNotificationsRequest } from "../../store/actions";

const NotificationSubscription = () => {
  const userId = Cookies.get("usersessionId", {
    secure: true,
    sameSite: "strict",
    path: "/",
  });
  const dispatch = useDispatch();

  useEffect(() => {
    // Subscribe to events for the specified collection
    const unsubscribe = client.subscribe(
      `databases.${appwritPointes.databaseID}.collections.${appwritPointes.notificationID}.documents`,
      (response) => {
        if (
          response.events.includes(
            "databases.*.collections.*.documents.*.create"
          )
        ) {
          handleCreateEvent(response, userId);
        }
      }
    );

    // Cleanup the subscription when the component unmounts
    return () => {
      unsubscribe();
    };
  }, [userId, dispatch]);

  const handleCreateEvent = (response, userId) => {
    if (response.payload.serviceProviders?.$id === userId) {
      dispatch(fetchNotificationsRequest());
    }
  };


  return null; // This component doesn't render anything
};

export default NotificationSubscription;
