// actionTypes.js
export const ADD_SERVICE = "ADD_SERVICE"
export const UPDATE_SERVICE = "UPDATE_SERVICE"
export const REMOVE_SERVICE = "REMOVE_SERVICE"
export const SET_SELECTED_SERVICE = "SET_SELECTED_SERVICE"
export const REMOVE_SERVICE_SUCCESS = "REMOVE_SERVICE_SUCCESS"
export const ADD_SERVICE_SUCCESS = "ADD_SERVICE_SUCCESS"
export const FETCH_SERVICES_REQUEST = "FETCH_SERVICES_REQUEST"
export const FETCH_SERVICES_SUCCESS = "FETCH_SERVICES_SUCCESS"
export const FETCH_SERVICES_FAILURE = "FETCH_SERVICES_FAILURE"
export const FETCH_CATEGORIES_REQUEST = "FETCH_CATEGORIES_REQUEST"
export const FETCH_CATEGORIES_SUCCESS = "FETCH_CATEGORIES_SUCCESS"
export const FETCH_CATEGORIES_FAILURE = "FETCH_CATEGORIES_FAILURE"
export const FETCH_TYPES_REQUEST = "FETCH_TYPES_REQUEST"
export const FETCH_TYPES_SUCCESS = "FETCH_TYPES_SUCCESS"
export const UPDATE_SERVICE_UCCESS = "UPDATE_SERVICE_UCCESS"
