import { useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import client from "helpers/appwrite/appwriteConfig";
import appwritPointes from "helpers/appwrite/appwritePointes";
import Cookies from "js-cookie";
import { fetchOrdersRequest } from "../../store/orderManagement/actions";
import { fetchNotificationsRequest } from "store/actions";
import { batch } from "react-redux";
import { throttle } from 'lodash';

const OrderSubscription = ({ children }) => {
  const userId = Cookies.get("usersessionId", {
    secure: true, sameSite: 'None', path: "/"
  });
  const dispatch = useDispatch();

  const handleCreateEvent = useCallback(throttle((response) => {
    if (response.payload.serviceProviderID ) {
      batch(() => {
        dispatch(fetchOrdersRequest());
        dispatch(fetchNotificationsRequest());
      });
    }
  }, 1000), [dispatch, userId]);

  const handleUpdateEvent = useCallback(throttle((response) => {
    if (response.payload.serviceProviderID ) {
      batch(() => {
        dispatch(fetchOrdersRequest());
        dispatch(fetchNotificationsRequest());
      });
    }
  }, 1000), [dispatch, userId]);

  const handleDeleteEvent = useCallback(throttle((response) => {
    if (response.payload.serviceProviderID ) {
      batch(() => {
        dispatch(fetchOrdersRequest());
        dispatch(fetchNotificationsRequest());
      });
    }
  }, 1000), [dispatch, userId]);

  useEffect(() => {
    
    const unsubscribe = client.subscribe(
      `databases.${appwritPointes.databaseID}.collections.${appwritPointes.ordersID}.documents`,
      (response) => {
        if (response.events.includes('databases.*.collections.*.documents.*.create')) {
          handleCreateEvent(response);
        } else if (response.events.includes('databases.*.collections.*.documents.*.update')) {
          handleUpdateEvent(response);
        } else if (response.events.includes('databases.*.collections.*.documents.*.delete')) {
          handleDeleteEvent(response);
        }
      }
    );

    return () => {
      unsubscribe();
    };
  }, [handleCreateEvent, handleUpdateEvent, handleDeleteEvent]);

  return children;
};

export default OrderSubscription;
