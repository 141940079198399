import Cookies from "js-cookie"
import { call, put, takeEvery, takeLatest, } from "redux-saga/effects"
import * as actionTypes from "./actionTypes"
import * as userActions from "./actions"
import {
  databases,
  storage,
} from "../../../helpers/appwrite/appwriteConfig"
import { Query, ID } from "appwrite"
import appwritPointes from "helpers/appwrite/appwritePointes"
import { errorTranslations } from "./errorTranslations" // Import the translation mapping
import { toast } from "react-toastify"

function setCookieWithExpiration(name, value, days) {
  const expirationDate = new Date()
  expirationDate.setDate(expirationDate.getDate() + days)
  Cookies.set(name, value, {
    expires: expirationDate,
    secure: true,
    sameSite: "strict",
    path: "/",
  })
}


// Function to remove a cookie
function removeCookie(name) {
  try {
    Cookies.remove(name, { secure: true, sameSite: "strict", path: "/" })
  } catch (error) {
    console.error("Error removing cookies:", error)
  }
}



async function loginUserApi(email, password) {
  try {
    const user = await databases.listDocuments(
      appwritPointes.databaseID,
      appwritPointes.providers,
      [Query.equal("email", [email])]
    )
    if (user && password === user.documents[0].password) {
      await databases.updateDocument(
        appwritPointes.databaseID,
        appwritPointes.providers,
        user.documents[0].$id,
        { is_online: true }
      )


      if (user.documents[0].account_status === "paused_for_subscription_expiry") {
        toast.info("إشتراكك منتهي الصلاحية. الرجاء اختيار خطة جديدة أو التواصل مع الدعم لاستعادة الوصول support@dbsaloni.com", { autoClose: false })

        return false;

      } else if (user.documents[0].account_status === "action_required") {
        toast.info("حسابك معلق. يرجى التواصل مع الدعم لاستعادة الوصول support@dbsaloni.com", { autoClose: false })
        return false
      }
      return user.documents[0]
    }


  } catch (error) {
    toast.warning("يرجى التحقق من عنوان بريدك الإلكتروني او كلمة المرور ", { autoClose: true })
    return false
  }
}


function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(loginUserApi, user.email, user.password)


    if (response) {
      // Store user data in cookies with an expiration date (e.g., 7 days)
      setCookieWithExpiration("usersessionId", response.$id, 1)
      setCookieWithExpiration("usersessionId", response.$id, 1)
      // Cookies.set("authUser", response.$id)
      // Dispatch login success action with user information
      yield put(userActions.loginSuccess(response))
      // Redirect to dashboard
      history("/dashboard")

    } else {
      toast.error("يرجى التحقق من عنوان بريدك الإلكتروني او كلمة المرور ", { autoClose: true })
    }

  } catch (error) {
    toast.warning("يرجى التحقق من عنوان بريدك الإلكتروني او كلمة المرور ", { autoClose: true })
  }
}

async function getUserProfile(userId) {
  try {
    const result = await databases.listDocuments(
      appwritPointes.databaseID,
      appwritPointes.providers,
      [Query.equal("$id", [userId])]
    )
    if (result.total) {
      const userProfile = {
        account_status: result.documents[0].account_status,
        bank_account: result.documents[0].bank_account,
        account_name: result.documents[0].account_name,
        description: result.documents[0].description,
        is_online: result.documents[0].is_online,
        location: result.documents[0].address,
        is_business: result.documents[0].is_business,
        profile_img_url: result.documents[0].profile_img_url,
        created_at: result.documents[0].$createdAt,
        id: result.documents[0].$id,
        img_id: result.documents[0].img_id,
        documentId: result.documents[0].$id,
        full_name: result.documents[0].full_name,
        email: result.documents[0].email,
        salon_name: result.documents[0].salon_name,
        mobile: result.documents[0].mobile,
        location_longitude: result.documents[0].location_longitude,
        location_latitude: result.documents[0].location_latitude,
        // Add other user information fields as needed
      }

      return userProfile
    }
    return false
  } catch (error) {
    console.error("Error fetching user profile:", error)
    return null
  }
}
async function getRates(userId) {
  try {
    const result = await databases.listDocuments(
      appwritPointes.databaseID,
      appwritPointes.ratingsID,
      [Query.equal("service_provider_id", [userId])]
    )

    return result.documents

  } catch (error) {
    throw error
  }
}

async function getUserInfo(userId) {
  try {
    // Call your API endpoint to fetch user information

    const userProfile = await getUserProfile(userId)
    // Modify the response as needed and return user information

    return userProfile
  } catch (error) {
    throw error
  }
}

function* fetchUserInfoSaga(action) {
  try {
    const userInfo = yield call(getUserInfo, action.payload)
    const rates = yield call(getRates, action.payload)
    const notifications = yield call(fetchNotificationsApi);
    yield put(userActions.fetchNotificationsSuccess(notifications));


    if (userInfo.account_status === "paused_for_subscription_expiry") {
      toast.info("إشتراكك منتهي الصلاحية. الرجاء اختيار خطة جديدة أو التواصل مع الدعم لاستعادة الوصول support@dbsaloni.com", { autoClose: false })


    } else if (userInfo.account_status === "action_required") {
      toast.info("حسابك معلق. يرجى التواصل مع الدعم لاستعادة الوصول support@dbsaloni.com", { autoClose: false })
      yield put(userActions.logoutUser())

    }


    yield put(userActions.storeUserInfo(userInfo))
    if (rates.length) {
      yield put(userActions.storeUserRate(rates))
    }
  } catch (error) {
    // Handle error if needed
    throw error;
  }
}


// Worker saga to update order status
async function updateInfo(documentId, updatedData) {
  try {
    const updatedFormData = {
      full_name: updatedData.full_name,
      address: updatedData.location,
      bank_account: updatedData.bank_account,
      account_name: updatedData.account_name,
      description: updatedData.description,
      salon_name: updatedData.salon_name,
      mobile: updatedData.mobile,
      location_latitude: updatedData.location_latitude,
      location_longitude: updatedData.location_longitude,
    }

    if (updatedData.img) {
      if (updatedData.img_id) {
        // Check if the file exists before attempting to delete it
        const fileExists = await checkFileExists(
          appwritPointes.bucketID,
          updatedData.img_id
        )

        if (fileExists) {
          const response = await storage.deleteFile(
            appwritPointes.bucketID,
            updatedData.img_id
          )
        }
      }

      const imgUploaded = await uploadImage(updatedData.img)
      const imgUrl = imgUploaded.$id
        ? storage.getFileView(appwritPointes.bucketID, imgUploaded.$id).href
        : ""

      updatedFormData.profile_img_url = imgUrl
      updatedFormData.img_id = imgUploaded.$id
    }

    const response = await databases.updateDocument(
      appwritPointes.databaseID,
      appwritPointes.providers,
      documentId,
      updatedFormData
    )
    return response
  } catch (error) {
    throw error // Re-throw the error for higher-level error handling
  }
}

function* updateUser(action) {
  try {
    const { documentId, ...updatedData } = action.payload
    const updatedInfo = yield call(updateInfo, documentId, updatedData)

    yield put(userActions.fetchUserInfo(updatedInfo.$id))
  } catch (error) {
    throw error
  }
}

// Worker saga to update user location
function* updateUserLocation(action) {
  try {
    const { documentId, locationLatitude, locationLongitude } = action.payload;
    // Call the existing saga function to update user info, passing the updated location latitude and longitude
    const updatedInfo = yield call(updateInfo, documentId, {
      location_latitude: locationLatitude,
      location_longitude: locationLongitude
    });
    // Dispatch success action
    yield put(userActions.fetchUserInfo(updatedInfo.$id))
  } catch (error) {
    // Dispatch failure action
    yield put(userActions.updateUserLocationFailure(error));
  }
}

async function uploadImage(img) {
  try {
    return await storage.createFile(appwritPointes.bucketID, ID.unique(), img)
  } catch (error) {
    throw error
  }
}

async function checkFileExists(bucketID, fileID) {
  try {
    const file = await storage.getFile(bucketID, fileID)
    return !!file
  } catch (error) {
    return false // If an error occurs, consider the file as non-existent
  }
}

async function logout() {
  try {
    const userId = Cookies.get("usersessionId", {
      secure: true,
      sameSite: "strict",
      path: "/",
    });

    // Retrieve the token from local storage
    const storedToken = localStorage.getItem('firebaseMessagingToken');

    // Retrieve the existing tokens
    const res = await databases.listDocuments(
      appwritPointes.databaseID,
      appwritPointes.providers,
      [Query.equal("$id", [userId])]
    );

    if (res.documents[0]) {
      const existingTokensArray = res.documents[0].notification_token || [];

      // Remove the token from the array
      const updatedTokensArray = existingTokensArray.filter(token => token !== storedToken);

      // Update the document with the new array
      const responss = await databases.updateDocument(
        appwritPointes.databaseID,
        appwritPointes.providers,
        res.documents[0].$id,
        { notification_token: updatedTokensArray, is_online: false }
      );
    }
  } catch (error) {
    throw error;
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    // Remove session cookie
    yield call(logout)
    removeCookie("usersessionId")
    localStorage.removeItem('firebaseMessagingToken');
    localStorage.removeItem('firebaseMessagingTokenDispatched');

    removeCookie("usersessionId")
    // Dispatch logout success action
    yield call(logout)
    yield put(userActions.logoutUserSuccess())

    history("/login")
  } catch (error) {
    yield put(userActions.apiError(error))
  }
}

async function openClose(status) {
  try {
    const userId = Cookies.get("usersessionId", {
      secure: true,
      sameSite: "strict",
      path: "/",
    })
     const res = await databases.updateDocument(
      appwritPointes.databaseID,
      appwritPointes.providers,
      userId,
      { is_online: status }
    )
   return res;
  } catch (error) {
    throw error
  }
}

function* openShop({ payload: { status } }) {
  try {
   const response =  yield call(openClose, status)
    yield put(userActions.fetchUserInfo(response.$id))
 
  } catch (error) {
throw error
  }
}
async function changPass(data) {

  try {
    const { newPassword } = data.currentPassword

    const userId = Cookies.get("usersessionId", {
      secure: true,
      sameSite: "None",
      path: "/",
    })

    const res = await databases.listDocuments(
      appwritPointes.databaseID,
      appwritPointes.providers,
      [Query.equal("$id", [userId])]
    )

    if (res) {
      const newdata = await databases.updateDocument(
        appwritPointes.databaseID,
        appwritPointes.providers,
        res.documents[0].$id,
        { password: newPassword }
      )

      return newdata.$id
    }
  } catch (error) {
    toast.error("  يرجى التحقق من كلمة المرور  ", { autoClose: true })
    throw new Error("  يرجى التحقق من كلمة المرور  ")
  }
}

function* changePassword(action) {
  try {
    // Call your API function to change the password
    const response = yield call(changPass, action.payload)
    // Dispatch a success action
    yield put(userActions.fetchUserInfo(response))
    yield put({ type: actionTypes.CHANGE_PASSWORD_SUCCESS, payload: response })
  } catch (error) {
    // Dispatch a failure action in case of an error
    yield put({ type: actionTypes.CHANGE_PASSWORD_FAILURE, error })
  }
}


function* changeEmail(action) {
  try {
    const { newEmail, password } = action.payload
    // Call your API function to change the email
    const response = yield call(updateEmail, newEmail, password)

    yield put({ type: actionTypes.FETCH_USER_INFO, payload: response })
    // Dispatch a success action
    yield put({ type: actionTypes.CHANGE_EMAIL_SUCCESS, payload: response })
  } catch (error) {
    // Translate the error message if a translation exists; otherwise, use the original message
    const translatedError = errorTranslations[error.message] || error.message

    // Dispatch a failure action with the translated error message
    yield put({
      type: actionTypes.CHANGE_EMAIL_FAILURE,
      error: translatedError,
    })
  }
}




// api.js or within the same saga file
async function fetchNotificationsApi() {
  const userId = Cookies.get("usersessionId", { secure: true, sameSite: "strict", path: "/" })
  try {
    const result = await databases.listDocuments(
      appwritPointes.databaseID,
      appwritPointes.notificationID, // assuming notificationsID is the collection ID
      [Query.equal("serviceProviders", [userId])]
    );
    return result.documents;
  } catch (error) {
    throw error;
  }
}



function* fetchNotifications() {
  try {
    const notifications = yield call(fetchNotificationsApi);
    yield put(userActions.fetchNotificationsSuccess(notifications));
  } catch (error) {
    throw error;
  }
}



async function clearNotifications(notificationId) {

  try {

      await databases.updateDocument(
      appwritPointes.databaseID, 
      appwritPointes.notificationID, 
      notificationId, 
      {seen: true}
    );

  } catch (error) {
    throw error;
  }
}

function* clearNotificationsSaga({payload}) {
  try {
     yield call(clearNotifications, payload.notificationId);
    const notifications = yield call(fetchNotificationsApi);
    yield put(userActions.fetchNotificationsSuccess(notifications));
  } catch (error) {
    throw error;
  }

}

async function SaveToken(token) {
  try {
    const userId = Cookies.get("usersessionId", {
      secure: true, sameSite: 'None',
      path: "/"
    });

    const res = await databases.listDocuments(
      appwritPointes.databaseID,
      appwritPointes.providers,
      [Query.equal("$id", [userId])]
    );

    if (res.documents[0]) {
      const existingTokensArray = res.documents[0].notification_token || [];

      // Check if the token already exists in the array
      if (!existingTokensArray.includes(token)) {
        const updatedTokensArray = [...existingTokensArray, token];
         await databases.updateDocument(
          appwritPointes.databaseID,
          appwritPointes.providers,
          res.documents[0].$id,
          { notification_token: updatedTokensArray }
        );
      }
    }
  } catch (error) {
    throw error;
  }
}


function* saveUserToken(action) {
  try {
    const token = action.payload;
    // Save the token in cookies with an expiration date (e.g., 7 days)
    const response = yield call(SaveToken, token)
    // Optionally, dispatch a success action if needed
  } catch (error) {
    throw error;
  }
}








function* authSaga() {
  yield takeEvery(actionTypes.CLEAR_NOTIFICATIONS, clearNotificationsSaga)
  yield takeEvery(actionTypes.LOGIN_USER, loginUser)
  yield takeEvery(actionTypes.LOGOUT_USER, logoutUser)
  yield takeLatest(actionTypes.FETCH_USER_INFO, fetchUserInfoSaga)
  yield takeLatest(actionTypes.UPDATE_USER_INFO_REQUEST, updateUser)
  yield takeLatest(actionTypes.CHANGE_PASSWORD_REQUEST, changePassword)
  yield takeLatest(actionTypes.CHANGE_EMAIL_REQUEST, changeEmail)
  yield takeLatest(actionTypes.UPDATE_USER_LOCATION_REQUEST, updateUserLocation);
  yield takeLatest(actionTypes.FETCH_NOTIFICATIONS_REQUEST, fetchNotifications);
  yield takeEvery(actionTypes.SAVE_USER_TOKEN, saveUserToken); // Add this line
  yield takeEvery(actionTypes.OPEN_SHOP_STATUS, openShop); // Add this line

  


}

export default authSaga
