import React, { useEffect, useRef, useState } from "react";
import { Container } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useSelector, useDispatch } from "react-redux";
import debounce from "lodash.debounce";

import {
  addService,
  updateService,
  removeService,
  setSelectedService,
  fetchServicesRequest,
} from "../../store/servicesManagment/actions";
import ServiceList from "./ServiceList";
import AddServiceForm from "./AddServiceForm";
import EditServiceForm from "./EditServiceForm";

const NewPage = () => {
  document.title = "ادارة الخدمات | DBsaloni Admin & Dashboard ";
  const dispatch = useDispatch();
  const services = useSelector((state) => state.service.services);
  const selectedService = useSelector((state) => state.service.selectedService);

  // Create a ref for the EditServiceForm component
  const editFormRef = useRef(null);
  const [shouldScroll, setShouldScroll] = useState(false);

  useEffect(() => {
    dispatch(fetchServicesRequest());
  }, [dispatch]);

  const handleAddService = (newService) => {
    dispatch(addService(newService));
  };

  const handleUpdateService = debounce((updatedService) => {
    dispatch(updateService(updatedService));
  }, 500); // Adjust the debounce delay as needed

  const handleRemoveService = (serviceId) => {
    dispatch(removeService(serviceId));
  };

  // Function to scroll to the EditServiceForm component
  const scrollToEditForm = () => {
    if (editFormRef.current) {
      editFormRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (shouldScroll && editFormRef.current) {
      scrollToEditForm();
      setShouldScroll(false); // Reset the flag after scrolling
    }
  }, [shouldScroll]);

  return (
    <>
      <div className="page-content">
        <Container fluid={false}>
          <Breadcrumbs
            title=" اضافة خدمة جديدة "
            breadcrumbItem="ادارة الخدمات "
          />

          <h2>عرض الخدمات</h2>
          <ServiceList
            services={services}
            handleEditService={(service) => {
              dispatch(setSelectedService(service));
              setShouldScroll(true); // Set the flag to scroll
            }}
            handleRemoveService={handleRemoveService}
          />

          <h2>إضافة خدمة جديدة</h2>
          <AddServiceForm handleAddService={handleAddService} />

          {selectedService && (
            <div ref={editFormRef}>
              <h2>تعديل الخدمة</h2>
              <EditServiceForm
                selectedService={selectedService}
                handleUpdateService={handleUpdateService}
              />
            </div>
          )}
        </Container>
      </div>
    </>
  );
};

export default NewPage;
