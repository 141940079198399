import React from "react"
import { Container } from "reactstrap"
//components
import UserProfile from "./user-profile"


const newPage = () => {
  //meta title
  document.title = "ادارة الحساب| DBsaloni Admin & Dashboard "
  return (
    <React.Fragment>
      <Container fluid>
        <UserProfile />
      </Container>
    </React.Fragment>
  )
}

export default newPage
