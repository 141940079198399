import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Button,
  FormFeedback
} from "reactstrap";

const EditServiceForm = ({ selectedService, handleUpdateService }) => {
  // Get data from Redux store
  const { categories, types } = useSelector(state => state.service);
  const [isLoading, setIsLoading] = useState(false);

  // Initialize state for form data and validation errors
  const [formData, setFormData] = useState({
    serviceName: "",
    serviceDescription: "",
    serviceType: "",
    categoryType: "",
    servicePrice: 0,
    avgExecuteTime: 0,
    atHomeServiceCost: 0,
  });

  const [errors, setErrors] = useState({});

  // Update formData when selectedService changes
  useEffect(() => {
    if (selectedService) {
      setFormData({
        serviceName: selectedService.name || "",
        servicePrice: selectedService.service_price || 0,
        serviceDescription: selectedService.description || "",
        serviceType: selectedService.service_type || "",
        categoryType: selectedService.service_category || "",
        atHomeServiceCost: selectedService.at_home_service_cost || 0,
        avgExecuteTime: selectedService.avg_execute_time || 0,
        docId: selectedService.$id,
      });
    }
  }, [selectedService]);
  

  // Handle input changes
  const handleInputChange = e => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
    if (errors[id]) {
      setErrors({ ...errors, [id]: undefined });
    }
  };

  // Validate form fields
  const validateFields = () => {
    const newErrors = {};
    if (!formData.serviceName.trim()) newErrors.serviceName = "اسم الخدمة مطلوب.";
    if (!formData.serviceDescription.trim()) newErrors.serviceDescription = "الوصف مطلوب.";
    if (!formData.serviceType.trim()) newErrors.serviceType = "نوع الخدمة مطلوب.";
    if (!formData.categoryType.trim()) newErrors.categoryType = "قسم الخدمة مطلوب.";
    if (!(formData.servicePrice >= 0)) newErrors.servicePrice = "يجب  إدخال سعر الخدمة";
    if (!(formData.avgExecuteTime >= 0)) newErrors.avgExecuteTime = "الوقت المتوقع";
    if (!(formData.atHomeServiceCost >= 0)) newErrors.atHomeServiceCost = "يجب إدخال سعر الخدمة في المنزل";
    return newErrors;
  };
  
  // Handle form submission
  const handleSubmit = e => {
    e.preventDefault();

    if (!selectedService || !selectedService.$id) {
      return; // Handle error if document ID is missing
    }
    
    const newErrors = validateFields();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    
    setIsLoading(true);
    
    const updatedService = {
      collId: selectedService.$collectionId,
      name: formData.serviceName,
      description: formData.serviceDescription,
      service_type: formData.serviceType,
      service_category: formData.categoryType,
      service_price: parseFloat(formData.servicePrice),
      avgExecuteTime: parseFloat(formData.avgExecuteTime),
      atHomeServiceCost: parseFloat(formData.atHomeServiceCost),
      id: selectedService.$id, // Use document ID from selectedService
    };

    setTimeout(() => {
      setIsLoading(false);
      handleUpdateService(updatedService); // Dispatch thunk action
    }, 1000);
  };

  
    

  

  return (
    <Container fluid={true}>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <CardTitle className="h4">تعديل الخدمة</CardTitle>
              <Form onSubmit={handleSubmit}>
                <FormGroup>
                  <Label for="serviceName">اسم الخدمة</Label>
                  <Input
                    type="text"
                    id="serviceName"
                    value={formData.serviceName}
                    onChange={handleInputChange}
                    invalid={!!errors.serviceName}
                  />
                  <FormFeedback>{errors.serviceName}</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label for="servicePrice">السعر</Label>
                  <Input
                    type="number"
                    id="servicePrice"
                    value={formData.servicePrice}
                    onChange={handleInputChange}

                    invalid={!!errors.servicePrice}
                  />
                  <FormFeedback>{errors.servicePrice}</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label for="serviceDescription">الوصف</Label>
                  <Input
                    type="textarea"
                    id="serviceDescription"
                    value={formData.serviceDescription}
                    onChange={handleInputChange}
                    invalid={!!errors.serviceDescription}
                  />
                  <FormFeedback>{errors.serviceDescription}</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label for="serviceType">نوع الخدمة</Label>
                  <Input
                    type="select"
                    id="serviceType"
                    value={formData.serviceType}
                    onChange={handleInputChange}
                    invalid={!!errors.serviceType}
                  >
                    <option value="" disabled>
                      اختر نوع الخدمة
                    </option>
                    {Array.isArray(types) &&
                      types.map(type => (
                        <option key={type.$id} value={type.title}>
                          {type.title}
                        </option>
                      ))}
                  </Input>
                  <FormFeedback>{errors.serviceType}</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label for="categoryType">قسم الخدمة</Label>
                  <Input
                    type="select"
                    id="categoryType"
                    value={formData.categoryType}
                    onChange={handleInputChange}
                    invalid={!!errors.categoryType}
                  >
                    <option value="" disabled>
                      اختر قسم الخدمة
                    </option>
                    {Array.isArray(categories) &&
                      categories.map(category => (
                        <option key={category.$id} value={category.title}>
                          {category.title}
                        </option>
                      ))}
                  </Input>
                  <FormFeedback>{errors.categoryType}</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label for="avgExecuteTime">الوقت المتوقع للتنفيذ (بالدقائق)</Label>
                  <Input
                    type="number"
                    id="avgExecuteTime"
                    value={formData.avgExecuteTime}
                    onChange={handleInputChange}

                    invalid={!!errors.avgExecuteTime}
                  />
                  <FormFeedback>{errors.avgExecuteTime}</FormFeedback>
                </FormGroup>
              {
                formData.serviceType !== "المركز" && (
                  <FormGroup>
                    <Label for="atHomeServiceCost">تكلفة الخدمة في المنزل</Label>
                    <Input
                      type="number"
                      id="atHomeServiceCost"
                      value={formData.atHomeServiceCost}
                      onChange={handleInputChange}
                      invalid={!!errors.atHomeServiceCost}
                    />
                    <FormFeedback>{errors.atHomeServiceCost}</FormFeedback>
                  </FormGroup>
                )
              }
                <Button type="submit" color="primary" disabled={isLoading}>
                  {isLoading ? (
                    <span>
                      <i className="fas fa-spinner fa-spin"></i> جاري الحفظ...
                    </span>
                  ) : (
                    'حفظ التغييرات'
                  )}
                </Button>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default EditServiceForm;
