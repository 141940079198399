import React from "react"
import { Container, } from "reactstrap"

import FinancialReport from "./FinancialReport"
import ResolvedTransferTransactions from "./ResolvedTransferTransactions ";
import Breadcrumbs from "../../components/Common/Breadcrumb"


const newPage = () => {
  // meta title
  document.title = "المستحقات| DBsaloni Admin & Dashboard "

  return (
    <>
      <div className="page-content">
        <Container fluid={false}>
          <Breadcrumbs
            title=" المستحقات"
            breadcrumbItem="ادارة المستحقات"
          />
          {/* Financial Report Section */}
          <ResolvedTransferTransactions />
          <FinancialReport />

        </Container>
      </div>
    </>
  )
}

export default newPage