import React from "react"
import PropTypes from "prop-types"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Table } from "reactstrap"

const OrdersModal = (props) => {
  const { isOpen, toggle, orderData } = props
  
  if (!orderData || !orderData.booking_execute_date || !orderData.app_user_id || !orderData.serviceProviderServices) {
    return null; // Return null if no order data or booking_execute_date is available
  }


  const appUserId = orderData.app_user_id || {} // Use an empty object as a fallback

  const translateStatus = (status) => {
    const translations = {
      pending: "قيد الانتظار",
      approved: "تم الموافقة عليه",
      canceled_by_customer: "تم الإلغاء من قبل العميل",
      rejected: "تم الرفض",
      completed: "تم الاكتمال",
      paid: "تم الدفع",
      failed: "فشل",
    }

    return translations[status] || status
  }

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>تفاصيل الطلب</ModalHeader>
        <ModalBody>
          <p className="mb-2">
            <strong>تاريخ إنشاء الطلب:</strong>{" "}
            <span className="text-primary">
              {orderData.$createdAt
                ? new Date(orderData.$createdAt).toLocaleString("ar-EG", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                  })
                : "Invalid date"}
            </span>
          </p>
          <p className="mb-2">
            <strong>الخدمة المطلوبة:</strong> {orderData?.serviceProviderServices?.name ?? "اسم الخدمة غير متوفر"}
          </p>
          <p className="mb-2">
            <strong>قسم الخدمة:</strong> {orderData.category_type ?? "غير متوفر"}
          </p>
          <p className="mb-2">
            <strong>التعليقات:</strong> {orderData.comment || "لا توجد تعليقات"}
          </p>
          <Table className="table align-middle table-nowrap">
            {/* صفوف الجدول للعناصر المطلوبة */}
          </Table>
          <hr />
          <div className="table-responsive">
            <Table className="table align-middle table-nowrap">
              <tbody>
                <tr>
                  <td>
                    <strong>الإجمالي :</strong>
                  </td>
                  <td>{orderData.total ?? 0} ريال</td>
                </tr>

                <tr>
                  <td>
                    <strong>نسبة عمولة :</strong>
                  </td>
                  <td>{orderData.platform_commission ?? 0}%</td>
                </tr>
                <tr>
                  <td>
                    <strong>الإجمالي بعد خصم عمولة:</strong>
                  </td>
                  <td>{orderData.total_after_discount ?? 0} ريال</td>
                </tr>
                <tr>
                  <td>
                    <strong>طريقة الدفع:</strong>
                  </td>
                  <td>{orderData.paymentMethod ?? "غير متوفر"}</td>
                </tr>
                <tr>
                  <td>
                    <strong>حالة الدفع:</strong>
                  </td>
                  <td>{translateStatus(orderData.paymentStatus)}</td>
                </tr>
                {appUserId.username && (
                  <>
                    <tr>
                      <td>
                        <strong>اسم المستخدم:</strong>
                      </td>
                      <td>{appUserId.username}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>رقم الهاتف:</strong>
                      </td>
                      <td>{appUserId.mobile}</td>
                    </tr>
                  </>
                )}
                <tr>
                  <td>
                    <strong>تاريخ تنفيذ الحجز:</strong>
                  </td>
                  <td>
                    {orderData.booking_execute_date
                      ? new Date(orderData.booking_execute_date).toLocaleString("ar-EG", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                        })
                      : "Invalid date"}
                  </td>
                </tr>

                <tr>
                  <td>
                    <strong>عدد المستفيدين من الخدمة:</strong>
                  </td>
                  <td>{orderData.The_number_of_beneficiaries ?? "غير متوفر"}</td>
                </tr>

                <tr>
                  <td>
                    <strong>حالة الحجز:</strong>
                  </td>
                  <td>{translateStatus(orderData.booking_status)}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={toggle}>
            إغلاق
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  )
}

OrdersModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  orderData: PropTypes.object,
}

export default OrdersModal
