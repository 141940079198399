// appwriteConfig.js

const appwritPointes = {
  endpoint: process.env.REACT_APP_APPWRITE_ENDPOINT,
  project: process.env.REACT_APP_APPWRITE_PROJECT_ID,
  databaseID: process.env.REACT_APP_APPWRITE_DATABASE_ID,
  categoriseID: process.env.REACT_APP_APPWRITE_CATEGORISE_ID,
  invoicesID: process.env.REACT_APP_APPWRITE_SERVICE_PROVIDER_SALE_INVOICES_ID,
  notificationID:process.env.REACT_APP_APPWRITE_SERVICE_PROVIDERS_NOTIFICATIONS_ID,
  ratingsID: process.env.REACT_APP_APPWRITE_CUSTOMER_RATINGS_ID,
  servicesID: process.env.REACT_APP_APPWRITE_SERVICE_PROVIDER_SERVICES_ID,
  ordersID: process.env.REACT_APP_APPWRITE_ORDERS_ID,
  bucketID: process.env.REACT_APP_APPWRITE_BUCKET_ID,
  types: process.env.REACT_APP_APPWRITE_SERVICE_TYPE_ID,
  providers: process.env.REACT_APP_APPWRITE_PROVIDERS_ID,
  rates: process.env.REACT_APP_APPWRITE_PROVIDERS_CUSTOMER_RATINGS_ID,
  googleMapId: process.env.REACT_APP_GOOGLE_MAP_ID,
  appnotificationID: process.env.REACT_APP_APPWRITE_APP_NOTIFICATION,

  
  // Add other configurations as needed
}

export default appwritPointes
