import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import appwritPointes from "helpers/appwrite/appwritePointes";
import { updateUserLocationRequest, fetchUserInfo } from "store/actions";
import { useLoadScript, StandaloneSearchBox } from "@react-google-maps/api";
import { toast } from "react-toastify";

const libraries = ["places"]; // Include the places library for search
const LoadingStyle = {
  textAlign: 'center',
  width: '100%',
  height: '15rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  fontSize: '27px',
  fontWeight: 700,
};
const LoadingContainer = () => (
  <div style={LoadingStyle}> <b>🌐</b> جاري تحميل الخريطة</div>
);

const MapsGoogle = (props) => {
  // Meta title
  document.title = "Google Maps | DBSaloni - Admin";

  const { location_latitude = 0, location_longitude = 0, documentId } = useSelector(
    (state) => state.Login.user || {}
  );

  const [storePosition, setStorePosition] = useState({
    lat: parseFloat(location_latitude),
    lng: parseFloat(location_longitude),
  });

  const [selectedMarkerPosition, setSelectedMarkerPosition] = useState(storePosition);
  const [isCurrentLocationSelected, setIsCurrentLocationSelected] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [selectedPlace, setSelectedPlace] = useState(null);

  const dispatch = useDispatch();
  const searchBoxRef = useRef(null);
  const mapRef = useRef(null);

  useEffect(() => {
    const usersessionId = Cookies.get("userId", {
      secure: true,
      sameSite: "strict",
      path: "/",
    });
    if (usersessionId) {
      dispatch(fetchUserInfo(usersessionId));
    }
  }, [dispatch]);

  useEffect(() => {
    setStorePosition({
      lat: parseFloat(location_latitude),
      lng: parseFloat(location_longitude),
    });
    setSelectedMarkerPosition({
      lat: parseFloat(location_latitude),
      lng: parseFloat(location_longitude),
    });
  }, [location_latitude, location_longitude]);

  useEffect(() => {
    if (selectedPlace) {
      const lat = selectedPlace.geometry.location.lat();
      const lng = selectedPlace.geometry.location.lng();
      setStorePosition({ lat, lng });
      setSelectedMarkerPosition({ lat, lng });
      setIsCurrentLocationSelected(false);
    }
  }, [selectedPlace]);

  const handleMapClick = (mapProps, map, clickEvent) => {
    const { latLng } = clickEvent;
    const lat = latLng.lat();
    const lng = latLng.lng();
    setSelectedMarkerPosition({ lat, lng });
    setIsCurrentLocationSelected(false);
  };

  const handleAddLocation = () => {
    if (!isCurrentLocationSelected) {
      dispatch(
        updateUserLocationRequest(
          documentId,
          selectedMarkerPosition.lat,
          selectedMarkerPosition.lng
        )
      );
      toast.success("تم تحديث موقعك بنجاح");

      setStorePosition(selectedMarkerPosition);
      setIsCurrentLocationSelected(true);
    } else {
      toast.error("الرجاء تحديد موقع جديد على الخريطة.");
    }
  };

  const handleCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          setSelectedMarkerPosition({ lat, lng });
          setStorePosition({ lat, lng });
          if (mapRef.current) {
            const map = mapRef.current.map; // Access the Google Maps instance
            setIsCurrentLocationSelected(false);
            if (map) {
              map.panTo({ lat, lng });
            }
          }
        },
        (error) => {
          toast.error(`خطأ: فشل خدمة تحديد الموقع الجغرافي (${error.message})`);
        }
      );
    } else {
      toast.error("لا يدعم متصفحك تحديد الموقع الجغرافي");
    }
  };
  

  const mapContainerStyle = {
    height: "50vh",
    width: "100%",
  };

  const style = {
    overflow: "hidden",
    height: "59vh",
    paddingBottom: "1rem",
  };

  const buttonStyle = {
    fontSize: "1rem",
    fontWeight: "800",
    backgroundColor: '#556ee6',
  };

  const buttDiv = {
    display: "flex",
    justifyContent: "center",
    gap: "1rem",
    width: "100%",
    marginBottom: "1rem",
    padding: "1rem",
  };

  const inputStyle = {
    boxSizing: 'border-box',
    border: '1px solid #6841ea47',
    width: '100%',
    height: '58px',
    padding: '0px 12px',
    borderRadius: '10px', // Corrected typo (r-radius -> borderRadius)
    boxShadow: 'rgba(0, 0, 0, 0.3) 0px 2px 6px',
    fontSize: '14px',
    outline: 'none',
    textOverflow: 'ellipsis',
  };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: appwritPointes.googleMapId,
    libraries,
  });

  if (loadError) return <div>عذرًا ، لا يمكن تحميل الخريطة حاليًا. يرجى تحديث الصفحة ومحاولة مرة أخرى.</div>;
  if (!isLoaded) return <LoadingContainer />;

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody style={style}>
              <StandaloneSearchBox
                onLoad={(ref) => (searchBoxRef.current = ref)}
                onPlacesChanged={() => {
                  const places = searchBoxRef.current.getPlaces();
                  if (places.length === 0) return;
                  const place = places[0];
                  setSelectedPlace(place);
                  setSearchInput(place.formatted_address);
                }}
              >
                <input
                  type="text"
                  placeholder="ابحث عن عنوان الفرع"
                  style={inputStyle}
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
              </StandaloneSearchBox>
              <div id="map" style={{ position: "relative", paddingTop: "1rem" }}>
                <Map
                  google={props.google}
                  style={mapContainerStyle}
                  zoom={14}
                  center={storePosition}
                  onClick={handleMapClick}
                  ref={mapRef}
                >
                  {selectedMarkerPosition && (
                    <Marker position={selectedMarkerPosition} />
                  )}
                </Map>
              </div>
            </CardBody>
            <div style={buttDiv}>
              <Button
                style={buttonStyle}
                onClick={handleAddLocation}
                disabled={isCurrentLocationSelected}
              >
                إضافة الموقع
              </Button>
              <Button
                style={buttonStyle}
                onClick={handleCurrentLocation}
              >
                الموقع الحالي
              </Button>
            </div>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

MapsGoogle.propTypes = {
  google: PropTypes.object.isRequired,
};

export default GoogleApiWrapper({
  apiKey: appwritPointes.googleMapId,
  LoadingContainer: LoadingContainer,
  v: "3", // Use Google Maps API version 3
})(MapsGoogle);
