import React from "react";
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import ApexRadial from "./ApexRadial";

const MonthlyEarning = ({ currentMonthEarning, earningImprovementPercentage, dailyProfits }) => {
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-4">اجمالي الارباح اليومي</CardTitle>
          <Row>
            <Col sm="6">
              <p className="text-muted">اليوم </p>
              <h3>{`SAR ${currentMonthEarning}`}</h3>
              <p className="text-muted">
                <span className="text-success me-2">
                  {`${earningImprovementPercentage}%`} <i className="mdi mdi-arrow-up"></i>
                </span>
                تحسن
              </p>
              <div className="mt-4">
              </div>
            </Col>
            <Col sm="6">
              <div className="mt-4 mt-sm-0">
                <ApexRadial dataColors='["--bs-success"]' dailyProfits={dailyProfits} />
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default MonthlyEarning;