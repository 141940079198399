import { actionTypes } from "./actions";
import { call, put, takeEvery } from 'redux-saga/effects'
import { databases } from "helpers/appwrite/appwriteConfig";
import appwritPointes from "helpers/appwrite/appwritePointes";
import { Query } from "appwrite"
import Cookies from "js-cookie"


async function fetchInvoices(){
  try {
    const userId = Cookies.get("usersessionId", {
      secure: true,
      sameSite: "strict",
      path: "/",
    })
      const re = await databases.listDocuments(
        appwritPointes.databaseID,
        appwritPointes.invoicesID,
        [Query.equal("serviceProviders", [userId])]
  
      )
      return re.documents
  }catch(error){
    throw error
  }
}

function* fetchTransactionsSaga() {
    const respon = yield call(fetchInvoices)
  yield put({ type: actionTypes.SET_TRANSACTIONS, data: respon });
}


export default function* rootSaga() {
  yield takeEvery(actionTypes.FETCH_TRANSACTIONS, fetchTransactionsSaga);
}
