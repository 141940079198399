import React, { useEffect, useMemo, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { fetchTransactions } from "../../store/dbInvoices/actions"
import { Row, Col, Card, CardBody, Button } from "reactstrap"
import TableContainer from "../../components/Common/TableContainer"
import { parseISO, format } from 'date-fns';
import TransactionModal from "./TransactionModal" // Import the TransactionModal component




const ResolvedTransferTransactions = () => {
  const transactions = useSelector(state => state.transactions)
  const [selectedTransaction, setSelectedTransaction] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const dispatch = useDispatch()


  const invoices = [];

  // Assuming transactions is an array of objects with a 'booking' property containing the payment status
  const paidInvoices = transactions.filter((transaction) => transaction.paid_status === 'paid');

  // You can append the extracted transactions to the invoices array
  invoices.push(...paidInvoices);




  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }

  const handleRowClick = transaction => {
    setSelectedTransaction(transaction)
    toggleModal()
  }


  const columns = useMemo(
    () => [

      {
        Header: "تاريخ العملية",
        accessor: "created_at",
        Cell: (cellProps) => {
          return <span>{format(parseISO(cellProps.value), 'MM dd, yyyy')}</span>;
        },
      },
      {
        Header: "  مستفيد من الخدمة",
        accessor: "customerName",
        Cell: ({ row }) => (
          row.original.booking && row.original.booking.app_user_id.username
        ),

      },
      {
        Header: "المبلغ",
        accessor: "total",
        disableFilters: true,
        Cell: (cellProps) => {
          // Format the total amount with "ريال"
          const formattedTotal = cellProps.value + " ريال";
          return <span>{formattedTotal}</span>;
        },
      },
      {
        Header: " عرض التفاصيل",
        accessor: "action",
        disableFilters: true,
        Cell: ({ row }) => (
          <Button
            type="button"
            style={{ width: "100%" }}
            onClick={() => handleRowClick(row.original)}
            className={"btn btn-primary  text-center"}
          >
            عرض
          </Button>
        ),
      },
      {
        Header: "حالة الدفع",
        accessor: "paid_status",
        disableFilters: true,
        Cell: cellProps => {
          return <Status {...cellProps} />
        },
      },
    ],
    []
  )

  useEffect(() => {
    dispatch(fetchTransactions())
  }, [dispatch])

  return (

    <Row>
      <Col lg="12">
        <Card>
          <CardBody>
            {/* Your Financial Report Table */}
            <h2> حوالات المكتملة  </h2>
            <TableContainer
              columns={columns}
              data={invoices}
              customPageSize={10}
              className="custom-header-css"
            />
            <TransactionModal
              isOpen={isModalOpen}
              toggleModal={toggleModal}
              transaction={selectedTransaction}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default ResolvedTransferTransactions

const Status = cell => {
  const getStatusBadge = status => {
    switch (status) {
      case "paid":
        return "btn btn-success  btn-label text-center"
      case "pending":
        return "btn btn-warning  btn-label"
      case "failed":
        return "btn btn-danger  btn-label"
      default:
        return "secondary"
    }
  }
  return (
    <Button
      type="button"
      style={{ width: "100%" }}
      className={`${getStatusBadge(cell.value)}`}
    >
      <i
        className={
          cell.value === "paid"
            ? "bx bx-check-double label-icon"
            : "" || cell.value === "pending"
              ? "bx bx-error label-icon"
              : "" || cell.value === "failed"
                ? "bx bx-block label-icon"
                : "" || cell.value === ""
                  ? ""
                  : ""
        }
      ></i>{" "}
      {translateStatus(cell.value)}
    </Button>
  )
}


const translateStatus = (status) => {
  const translations = {
    pending: "قيد الانتظار",
    paid: "تم  ",
    failed: "فشل  ",

  }

  return translations[status] || status;
};