import React from 'react';
import { Doughnut } from 'react-chartjs-2';

const OrderStatusChart = ({ totalCompletedOrders, totalPendingOrders }) => {
  
  const chartData = {
    labels: ['الطلبات المكتملة', 'قيد المراجعة'],
    datasets: [
      {
        data: [totalCompletedOrders, totalPendingOrders],
        backgroundColor: ['#36A2EB', '#FFCE56'],
      },
    ],
  };

  return (
    <div>
      <Doughnut data={chartData} />
    </div>
  );
};

export default OrderStatusChart;
