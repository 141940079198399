// reducer.js
import * as actionTypes from "./actionTypes"

const initialState = {
  services: [],
  categories: [],
  types: [],
  catLoadin: false,
  typeLoadin: false,
  totalServices: 0,
  totalCategories: 0,
  selectedService: null,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_SERVICE_UCCESS:
      const updatedService = action.payload // New service data

      const updatedServices = state.services.map(service =>
        service.$id === updatedService.$id ? updatedService : service
      )
      return {
        ...state,
        services: updatedServices,
        selectedService: null, // Update the selected service if needed
      }
    case actionTypes.ADD_SERVICE_SUCCESS:
      const newService = action.payload // Assuming action.payload is the entire service object
      return {
        ...state,
        services: [...state.services, newService],
        totalServices: state.totalServices + 1, // Increment totalServices
      }
    case actionTypes.REMOVE_SERVICE:
      const filteredServices = state.services.filter(
        service => service.$id !== action.payload
      )
      return {
        ...state,
        services: filteredServices,
        selectedService: null,
        totalServices: state.totalServices - 1, // Decrement totalServices
      }
    case actionTypes.REMOVE_SERVICE_SUCCESS:
      const serviceId = action.payload
      const updatedRemoveServices = state.services.filter(
        service => service.$id !== serviceId
      )
      return {
        ...state,
        services: updatedRemoveServices,
        totalServices: state.totalServices - 1, // Decrement totalServices
      }
    case actionTypes.FETCH_SERVICES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case actionTypes.FETCH_SERVICES_SUCCESS:
      return {
        ...state,
        loading: false,
        services: action.services,
        totalServices: action.services.length, // Update totalServices with the number of fetched services
      }

    case actionTypes.SET_SELECTED_SERVICE:
      return {
        ...state,
        selectedService: action.payload,
      }

    case actionTypes.FETCH_CATEGORIES_REQUEST:
      return {
        ...state,
        catLoadin: true,
      }
    case actionTypes.FETCH_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.payload,
        catLoadin: false,
        totalCategories: action.payload.length,
      }

    case actionTypes.FETCH_TYPES_REQUEST:
      return {
        ...state,
        tyLoadin: true,
      }
    case actionTypes.FETCH_TYPES_SUCCESS:
      return {
        ...state,
        types: action.payload,
        typeLoadin: false,
      }

    default:
      return state
  }
}

export default reducer
