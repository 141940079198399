import React, { useState, useMemo, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import * as action from '../../store/actions'

import { Row, Col, Card, CardBody, Button } from "reactstrap"
import TableContainer from "../../components/Common/TableContainer"
import { format, parseISO } from 'date-fns';
import TransactionModal from "./TransactionModal" // Import the TransactionModal component

function FinancialReport() {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalOpenDetails, setIsModalOpenDetails] = useState(false)

  const [selectedTransaction, setSelectedTransaction] = useState(null)
  const [selectedTransactionDetails, setSelectedTransactionDetails] = useState(null)

  const transactions = useSelector(state => state.transactions)
  const dispatch = useDispatch()

  const invoices = [];

  // Assuming transactions is an array of objects with a 'booking' property containing the payment status
  const paidInvoices = transactions.filter((transaction) => transaction.paid_status === 'pending');

  // You can append the extracted transactions to the invoices array
  invoices.push(...paidInvoices);



  useEffect(() => {
    dispatch(action.fetchTransactions())
  }, [dispatch])


  const handleRowClickDetails = transaction => {
    setSelectedTransactionDetails(transaction)
    toggleModalDetails()
  }

  const toggleModalDetails = () => {
    setIsModalOpenDetails(!isModalOpenDetails)
  }


  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }

  const handleRowClick = transaction => {
    setSelectedTransaction(transaction)
    toggleModal()
  }

  const columns = useMemo(
    () => [
      {
        Header: "تاريخ العملية",
        accessor: "created_at",
        Cell: (cellProps) => {
          return <span>{format(parseISO(cellProps.value), 'MM dd, yyyy')}</span>;
        },
      },
      {
        Header: "المبلغ",
        accessor: "total",
        disableFilters: true,
        Cell: (cellProps) => {
          // Format the total amount with "ريال"
          const formattedTotal = cellProps.value + " ريال";
          return <span>{formattedTotal}</span>;
        },
      },
      {
        Header: " مقدم الخدمة",
        accessor: "customerName",
        Cell: ({ row }) => (
          row.original.serviceProviders && row.original.serviceProviders.salon_name
        ),
      },
      {
        Header: "نوع العملية",
        accessor: "transactionType",
        Cell: ({ row }) => (
          row.original.booking && row.original.booking.paymentMethod
        ),
      },



      {
        Header: " عرض التفاصيل",
        accessor: "action",
        disableFilters: true,
        Cell: ({ row }) => (

          <Button
            type="button"
            style={{ width: "100%" }}
            onClick={() => handleRowClickDetails(row.original)}
            className={"btn btn-primary  text-center"}
          >
            عرض
          </Button>
        ),
      },

      {
        Header: "حالة المستحق  ",
        accessor: "paid_status",
        disableFilters: true,
        Cell: ({ row }) => (

          <Button
            type="button"
            style={{ width: "100%" }}
            disabled
            className={"btn btn-warning  text-center"}
          >
            الانتظار
          </Button>
        ),
      },
    ],
    []
  )

  return (
    <React.Fragment>
      <Row>
        <Col lg="12">
          {/* Your Financial Report Content */}
          <Card>
            <CardBody>
              {/* Your Financial Report Table */}
              <h2>مستحقات منتظرة </h2>

              <TableContainer
                columns={columns}
                data={invoices}
                isGlobalFilter={true}
                isAddOptions={false}
                customPageSize={10}
                className="custom-header-css"
              />

              <TransactionModal
                isOpen={isModalOpenDetails}
                toggleModal={toggleModalDetails}
                transaction={selectedTransactionDetails}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default FinancialReport