import React from "react"

import { Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"
import Rating from "react-rating"

import profileImg from "../../assets/images/profile-img.png"
import { useSelector } from "react-redux"
const WelcomeComp = () => {
  const { rates } = useSelector(state => state.Login)
  let totalRate = 0
  if (rates.length) {
    for (let i = 0; i < rates.length; i++) {
      totalRate += rates[i].rating
    }
  }

  const averageRating = rates.length > 0 ? totalRate / rates.length : 0
  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className="bg-primary bg-soft">
          <Row>
            <Col xs="7">
              <div className="text-primary p-3">
                <h5 className="text-primary">أهلا وسهلا!</h5>
              </div>
            </Col>
            <Col xs="5" className="align-self-end">
              <img src={profileImg} alt="" className="img-fluid" />
            </Col>
          </Row>
        </div>
        <CardBody className="pt-0">
          <Row>
            <Col>
              <div className="pt-4">
                <div className="mt-4">
                  <Link
                    to="/acount-management"
                    className="btn btn-primary  btn-sm"
                  >
                    الملف الشخصي <i className="mdi mdi-arrow-right ms-1"></i>
                  </Link>
                </div>
              </div>
            </Col>
            <Col>
              <div className="pt-4">
                <div className="mt-4">
                  <Rating
                    stop={5}
                    emptySymbol="mdi mdi-star-outline text-primary "
                    fullSymbol="mdi mdi-star text-primary "
                    className="rating-symbol-background"
                    initialRating={averageRating}
                    readonly
                  />
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
export default WelcomeComp
