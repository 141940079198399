import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { Routes, Route, Navigate } from "react-router-dom";
import { authProtectedRoutes, publicRoutes } from "./routes";
import Authmiddleware from "./routes/route";
import VerticalLayout from "./components/VerticalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";
import NotFound from "./pages/Utility/pages-404"; // Import your 404 component
import Cookies from "js-cookie"; // Import the Cookies library
import "react-toastify/dist/ReactToastify.css"
import { ToastContainer } from "react-toastify"
import "./assets/scss/theme.scss";
import RequestPermissionComponent from "./RequestPermissionComponent";
// import { SpeedInsights } from "@vercel/speed-insights/react"

const getLayout = () => {
  let Layout = VerticalLayout;
  return Layout;
};

const App = () => {
  const { layoutType } = useSelector((state) => ({
    layoutType: state.Layout.layoutType,
  }));

  const Layout = getLayout(layoutType);

  // Retrieve userId and usersessionId from cookies
  const userId = Cookies.get("usersessionId", { secure: true, sameSite: "strict", path: "/" });
  const usersessionId = Cookies.get("usersessionId", { secure: true, sameSite: "strict", path: "/" });

  return (
    <React.Fragment>
        <RequestPermissionComponent />
        {/* <SpeedInsights /> */}
         <ToastContainer
        position="top-center"
        autoClose={false}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"

      />

      <Routes>
        {publicRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={<NonAuthLayout>{route.component}</NonAuthLayout>}
            key={idx}
            exact={true}
          />
        ))}

        {authProtectedRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              userId && usersessionId ? ( // Check if userId and usersessionId cookies exist
                <Authmiddleware>
                  <Layout>{route.component}</Layout>
                </Authmiddleware>
              ) : (
                // Redirect to the login page if cookies do not exist
                <Navigate to="/login" />
              )
            }
            key={idx}
            exact={true}
          />
        ))}

        {/* Add a catch-all route for 404 */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any,
};

export default App;
