import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,

} from "reactstrap"

//import action
import { getChartsData as onGetChartsData } from "../../store/actions"
import { CardTitle } from "reactstrap"
import {
  fetchServicesRequest,
  fetchCategoriesRequest,
  fetchOrdersRequest,
  fetchTransactions,
} from "../../store/actions"
// Pages Components
import WelcomeComp from "./WelcomeComp"
import MonthlyEarning from "./MonthlyEarning"
import ActivityComp from "./ActivityComp"
import OrderStatusChart from "./OrderStatusChart"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
//i18n
import { withTranslation } from "react-i18next"
//redux
import { useSelector, useDispatch } from "react-redux"
import RequestPermissionComponent from "RequestPermissionComponent";

const Dashboard = props => {
  const dispatch = useDispatch()
  const { chartsData } = useSelector(state => state.Dashboard);
  const { services, totalServices, totalCategories } = useSelector(state => state.service);
  const { totalCompletedOrders, totalPendingOrders } = useSelector(state => state.orders);
  const transactions = useSelector(state => state.transactions);

  // Custom function to compare dates by year, month, and day
  function isSameDayIgnoringTime(date1, date2) {
    return date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate();
  }

  const selectedDate = new Date(); // You can replace this with a dynamic date

  // Assuming 'transactions' is an array of transaction objects available in the scope
  const dailyTransactions = transactions.filter(transaction => {
    const transactionDate = new Date(transaction.created_at);
    const isPaid = transaction.paid_status === 'paid'; // Check if the transaction is paid

    return isSameDayIgnoringTime(transactionDate, selectedDate) && isPaid;
  });

  const dailyProfit = dailyTransactions.reduce((acc, transaction) => acc + transaction.total, 0);

  const currentDate = new Date();
  const previousDate = new Date(currentDate);
  previousDate.setDate(currentDate.getDate() - 1);

  const previousDayTransactions = transactions.filter(transaction => {
    const transactionDate = new Date(transaction.created_at);
    return isSameDayIgnoringTime(transactionDate, previousDate);
  });

  const previousEarnings = previousDayTransactions.reduce((acc, transaction) => acc + transaction.total, 0);

  let earningImprovementPercentage = 0;
  if (previousEarnings !== 0) {
    earningImprovementPercentage = ((dailyProfit - previousEarnings) / previousEarnings) * 100;
  }







  const orders = useSelector(state => state.orders.orders);

  const totalCompletedAmount = orders
    .filter(order => order.booking_status === 'completed')
    .reduce((total, order) => total + order.total, 0);

  const totalPendingAmount = orders
    .filter(order => order.booking_status === 'pending' || order.booking_status === 'approved')
    .reduce((total, order) => total + order.total, 0);



  const invoices = [];

  // Assuming transactions is an array of objects with a 'booking' property containing the payment status
  const paidInvoices = transactions.filter((transaction) => transaction.paid_status === 'pending');

  // You can append the extracted transactions to the invoices array
  invoices.push(...paidInvoices);

  // Sum the total amount of all paid invoices
  const totalAmount = paidInvoices.reduce((acc, invoice) => acc + invoice.total, 0);




  const [reports, setReports] = useState([
    { title: "إجمالي قيمة الطلبات المكتملة", iconClass: "bx-archive-in", description: ` ${totalCompletedAmount} ريال` },
    { title: "إجمالي قيمة الطلبات", iconClass: "bx-archive-in", description: ` ${totalPendingAmount} ريال` },
    { title: "عدد الخدمات", iconClass: "bx-copy-alt", description: totalServices },
    { title: "عدد الاقسام", iconClass: "bx-archive-in", description: totalCategories },
    { title: " إجمالي قيمة المستحقات", iconClass: "bx-copy-alt", description: ` ${totalAmount} ريال` },
    { title: "عدد العملاء", iconClass: "bx-purchase-tag-alt", description: totalCompletedOrders }
  ]);

  const [periodData, setPeriodData] = useState([])
  const [periodType, setPeriodType] = useState("yearly")

  useEffect(() => {
    setReports(prevReports => [
      { title: "إجمالي قيمة الطلبات المكتملة", iconClass: "bx-archive-in", description: ` ${totalCompletedAmount} ريال` },
      { title: "إجمالي قيمة الطلبات", iconClass: "bx-archive-in", description: ` ${totalPendingAmount} ريال` },
      { title: " إجمالي قيمة المستحقات", iconClass: "bx-copy-alt", description: ` ${totalAmount} ريال` },
      { title: "عدد الخدمات", iconClass: "bx-copy-alt", description: totalServices },
      { title: "عدد الاقسام", iconClass: "bx-archive-in", description: totalCategories },
      { title: "عدد العملاء", iconClass: "bx-purchase-tag-alt", description: totalCompletedOrders }
    ]);
  }, [totalCompletedAmount, totalPendingAmount, totalServices, totalCategories, totalAmount, totalCompletedOrders]);

  useEffect(() => {
    dispatch(fetchServicesRequest())
    dispatch(fetchCategoriesRequest())
    dispatch(fetchOrdersRequest())
    dispatch(fetchTransactions());
  }, [dispatch])
  useEffect(() => {
    setPeriodData(chartsData)
  }, [chartsData])
  useEffect(() => {
    dispatch(onGetChartsData("yearly"))
  }, [dispatch])

  //meta title
  document.title = "الصفحة الرئيسية | DBsaloni"

  return (
    <React.Fragment>

      <div className="page-content">
        <RequestPermissionComponent />
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("الصفحة الرئيسية")}
          />

          <Row>
            <Col xl="4">
              <WelcomeComp />
              <MonthlyEarning
                currentMonthEarning={dailyProfit} // Assuming this is the monthly earning
                earningImprovementPercentage={earningImprovementPercentage.toFixed(2)}
                dailyProfits={dailyTransactions}// This should be calculated based on data
              />
              <ActivityComp services={services} />

            </Col>
            <Col xl="8">
              <Row>
                {/* Reports Render */}
                {reports.map((report, key) => (
                  <Col md="4" key={"_col_" + key}>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              {report.title}
                            </p>
                            <h4 className="mb-0">{report.description}</h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={
                                  "bx " + report.iconClass + " font-size-24"
                                }
                              ></i>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>

              <Card>
                <CardBody>
                  <CardTitle className="mb-4">
                    الرسوم البيانية معدل الطلبات
                  </CardTitle>
                  <Row className="justify-content-center">
                    <Col sm={4}>
                      <div className="text-center">
                        <h5 className="mb-0">{totalCompletedOrders}</h5>
                        <p className="text-muted text-truncate">
                          الطلبات المكتملة
                        </p>
                      </div>
                    </Col>
                    <Col sm={4}>
                      <div className="text-center">
                        <h5 className="mb-0">{totalPendingOrders}</h5>
                        <p className="text-muted text-truncate">قيد المراجعة</p>
                      </div>
                    </Col>

                  </Row>


                  <OrderStatusChart
                    totalCompletedOrders={totalCompletedOrders}
                    totalPendingOrders={totalPendingOrders}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Dashboard)
