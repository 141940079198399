// actions.js

// Define your action type constants
export const FETCH_ORDERS_REQUEST = "FETCH_ORDERS_REQUEST"
export const FETCH_ORDERS_SUCCESS = "FETCH_ORDERS_SUCCESS"
export const FETCH_ORDERS_FAILURE = "FETCH_ORDERS_FAILURE"
export const UPDATE_ORDER_STATUS_REQUEST = "UPDATE_ORDER_STATUS_REQUEST"
export const UPDATE_ORDER_STATUS_SUCCESS = "UPDATE_ORDER_STATUS_SUCCESS"
export const UPDATE_ORDER_STATUS_FAILURE = "UPDATE_ORDER_STATUS_FAILURE"

// Define your action creators
export const fetchOrdersRequest = () => ({
  type: FETCH_ORDERS_REQUEST,
})

export const fetchOrdersSuccess = data => ({
  type: FETCH_ORDERS_SUCCESS,
  payload: data,
})

export const fetchOrdersFailure = error => ({
  type: FETCH_ORDERS_FAILURE,
  payload: error,
})

// Action to update order status
export const updateOrderStatusRequest = (orderId, newStatus) => ({
  type: UPDATE_ORDER_STATUS_REQUEST,
  payload: { orderId, newStatus },
})

export function updateOrderStatusSuccess(updatedOrder) {
  return {
    type: UPDATE_ORDER_STATUS_SUCCESS,
    payload: updatedOrder,
  }
}

export function updateOrderStatusFailure(error) {
  return {
    type: UPDATE_ORDER_STATUS_FAILURE,
    payload: error,
  }
}



