import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Table,
} from "reactstrap";
import { format, parseISO } from 'date-fns';

const TransactionModal = ({ isOpen, toggleModal, transaction }) => {
  if (!transaction || !transaction.booking) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>تفاصيل فاتورة العميل</ModalHeader>
      <ModalBody>
        {transaction && (
          <Table>
            <tbody>
              <tr>
                <th scope="row">تاريخ العملية</th>
                <td>{transaction.booking.$createdAt ? format(parseISO(transaction.booking.$createdAt), 'MM dd, yyyy') : 'N/A'}</td>
              </tr>
              <tr>
                <th scope="row">المستفيد من الخدمة</th>
                <td>{transaction.booking.app_user_id?.username ?? 'N/A'}</td>
              </tr>
              <tr>
                <th scope="row">اسم الخدمة</th>
                <td>{transaction.booking.serviceProviderServices?.name ?? 'N/A'}</td>
              </tr>
              <tr>
                <th scope="row">قسم الخدمة</th>
                <td>{transaction.booking.category_type ?? 'N/A'}</td>
              </tr>
              <tr>
                <th scope="row">حالة الحجز</th>
                <td>{translateStatus(transaction.booking.booking_status) ?? 'N/A'}</td>
              </tr>
              <tr>
                <th scope="row">طريقة الدفع</th>
                <td>{transaction.booking.paymentMethod ?? 'N/A'}</td>
              </tr>
              <tr>
                <th scope="row">حالة الدفع</th>
                <td>{translateStatus(transaction.booking.paymentStatus) ?? 'N/A'}</td>
              </tr>
              <tr>
                <th scope="row">الإجمالي</th>
                <td>{transaction.total ?? 'N/A'} ريال</td>
              </tr>
              <tr>
                <th scope="row">نسبة عمولة</th>
                <td>{transaction.commission_rates ?? 'N/A'}%</td>
              </tr>
              <tr>
                <th scope="row">الإجمالي بعد الخصم عمولة</th>
                <td>{transaction.total_after_discount ?? 'N/A'} ريال</td>
              </tr>
            </tbody>
          </Table>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          type="button"
          style={{ width: "100%" }}
          className={"btn btn-primary  text-center"}
          onClick={toggleModal}
        >
          إغلاق
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const translateStatus = status => {
  const translations = {
    pending: "قيد الانتظار",
    paid: "تم الدفع ",
    pailed: "فشل دفع ",
    approved: "تم الموافقة عليه",
    rejected: "تم الرفض",
    completed: "تم الاكتمال",
  };
  return translations[status] || status;
};

export default TransactionModal;