export const actionTypes = {
  FETCH_TRANSACTIONS: 'FETCH_TRANSACTIONS',
  SET_TRANSACTIONS: 'SET_TRANSACTIONS',

};

export const fetchTransactions = () => ({
  type: actionTypes.FETCH_TRANSACTIONS,
});

export const setTransactions = data => ({
  type: actionTypes.SET_TRANSACTIONS,
  data,
});

