import React, { useState } from "react"
import PropTypes from "prop-types"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap"
import DOMPurify from "dompurify" // Import DOMPurify for input sanitization
import { toast } from "react-toastify" // Import toast from react-toastify

const StatusModal = ({
  isOpen,
  toggle,
  status,
  updateOrderStatus,
  orderId,
}) => {
  if(!status || !orderId || !updateOrderStatus ) {
    return null
  }

  const [newStatus, setNewStatus] = useState("")
  const [showConfirm, setShowConfirm] = useState(false) // State for showing confirmation modal
  const [loading, setLoading] = useState(false) // State for loading spinner

  const allowedStatusOptions = ["pending", "approved", "completed"]

  const handleStatusChange = event => {
    const sanitizedValue = DOMPurify.sanitize(event.target.value)
    if (status === "completed") {
      toast.error("لا يمكن تغيير الحالة بمجرد اكتمالها.")
      return
    }
    if (sanitizedValue === "completed") {
      setShowConfirm(true) // Show confirmation modal for "completed"
    } else {
      setNewStatus(sanitizedValue)
    }
  }

  const handleConfirmStatusUpdate = async () => {
    if (isValidStatus(newStatus)) {
      setLoading(true)
      try {
        await updateOrderStatus(orderId, newStatus)
        setLoading(false)
        toggle()
        toast.success("تم تحديث الحالة إلى " + translateStatus(newStatus) + " بنجاح.")
        setNewStatus("") // Reset the status after updating
      } catch (error) {
        setLoading(false)
        toast.error("فشل في تحديث الحالة.")
      }
    } else {
      toast.error("تحديث الحالة غير صالح.")
    }
  }

  const isValidStatus = status => {
    return newStatus !== "" && allowedStatusOptions.includes(status)
  }

  const handleConfirmComplete = () => {
    setNewStatus("completed")
    setShowConfirm(false)
  }

  const handleCancelComplete = () => {
    setShowConfirm(false)
    setNewStatus("")
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={toggle}
      >
        <div className="modal-content">
          <ModalHeader toggle={toggle}>تحديث حالة الطلب</ModalHeader>
          <ModalBody>
            <p>الحالة الحالية: {translateStatus(status)}</p>
            <div className="form-group">
              <label>اختر حالة جديدة:</label>
              <select
                className="form-control"
                value={newStatus}
                onChange={handleStatusChange}
              >
                <option disabled value="">
                  اختر حالة
                </option>
                {allowedStatusOptions.map(option => (
                  <option key={option} value={option}>
                    {translateStatus(option)}
                  </option>
                ))}
              </select>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={handleConfirmStatusUpdate} disabled={loading}>
              {loading ? <Spinner size="sm" /> : "تحديث الحالة"}
            </Button>{" "}
            <Button color="secondary" onClick={toggle} disabled={loading}>
              إلغاء
            </Button>
          </ModalFooter>
        </div>
      </Modal>

      <Modal isOpen={showConfirm} toggle={handleCancelComplete}>
        <ModalHeader toggle={handleCancelComplete}>تأكيد</ModalHeader>
        <ModalBody>
          إذا قمت بتحديث الحالة إلى "تم الاكتمال"، فلن تتمكن من إعادة
          التغيير. هل أنت متأكد أنك تريد المتابعة؟
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleConfirmComplete}>
            نعم، أكمل
          </Button>{" "}
          <Button color="secondary" onClick={handleCancelComplete}>
            إلغاء
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

StatusModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  status: PropTypes.string,
  updateOrderStatus: PropTypes.func,
  orderId: PropTypes.string,
}

export default StatusModal

const translateStatus = status => {
  const translations = {
    pending: " الانتظار",
    approved: " موافقة",
    completed: "تم الاكتمال",
    // Add more translations as needed
  }

  return translations[status] || status
}
