import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col, Modal, ModalHeader, ModalBody, Button } from "reactstrap";
import SimpleBar from "simplebar-react";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { clearNotificationsRequest } from '../../../store/actions';

const NotificationDropdown = (props) => {
  const [modal, setModal] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [menu, setMenu] = useState(false);

  const { notifications } = useSelector(state => state.Login);
  const dispatch = useDispatch();
  const unseenNotifications = notifications.filter(notification => !notification.seen);
  const unseenNotificationsCount = unseenNotifications.length;

  // Sort notifications by creation date in descending order (newest first)
  const sortedNotifications = notifications.slice().sort((a, b) => new Date(b.$createdAt) - new Date(a.$createdAt));
  const sortedUnseenNotifications = unseenNotifications.slice().sort((a, b) => new Date(b.$createdAt) - new Date(a.$createdAt));

  const handleNotificationClick = (notification) => {
    setSelectedNotification(notification);
    setModal(true);
  };

  const toggleModal = () => {
    if (selectedNotification && !selectedNotification.seen) {
      setSelectedNotification(null);
      dispatch(clearNotificationsRequest({ notificationId: selectedNotification.$id }));
    }
    setModal(!modal);
  };

  const getNotificationIcon = (type) => {
    switch (type) {
      case "PAYMENT":
        return "bx bx-wallet";
      case "PROMO":
        return "bx bx-gift";
      case "BOOKING":
        return "bx bx-cart";
      case "GENERAL":
        return "bx bx-info-circle";
      default:
        return "bx bx-info-circle";
    }
  };

  const notStyle = {
    borderLeftStyle: "groove",
    borderLeftColor: "red",
    padding: "1rem"
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle className="btn header-item noti-icon position-relative" tag="button" id="page-header-notifications-dropdown">
          <i className="bx bx-bell bx-tada" />
          <span className="badge bg-danger rounded-pill">{unseenNotificationsCount}</span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {props.t("إشعارارت")} </h6>
              </Col>
              <div className="col-auto">
                <a href="#" className="small" onClick={() => setModal(true)}>
                  {props.t("View All")}
                </a>
              </div>
            </Row>
          </div>

          {unseenNotificationsCount > 0 ? (
            <SimpleBar style={{ height: "230px" }}>
              {sortedUnseenNotifications.map(notification => (
                <div
                  className="text-reset notification-item"
                  key={notification.$id}
                  onClick={() => handleNotificationClick(notification)}
                >
                  <div className="d-flex">
                    <div className="avatar-xs me-3">
                      <span className="avatar-title bg-primary rounded-circle font-size-16">
                        <i className={getNotificationIcon(notification.notification_type)} />
                      </span>
                    </div>
                    <div className="flex-grow-1">
                      <h6 className="mt-0 mb-1">{notification.title}</h6>
                      <div className="font-size-12 text-muted">
                        <p className="mb-1">{notification.content.substring(0, 40)}.....</p>
                        <p className="mb-0">
                          <i className="mdi mdi-clock-outline" />{" "}
                          {notification.$createdAt
                            ? new Date(notification.$createdAt).toLocaleString("ar-EG", {
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric',
                              hour: 'numeric',
                              minute: 'numeric'
                            })
                            : 'Invalid date'}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </SimpleBar>
          ) : (
            <div className="p-3 text-center">
              {props.t("No new notifications")}
            </div>
          )}
        </DropdownMenu>
      </Dropdown>
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>{selectedNotification ? selectedNotification.title : props.t("إشعارارت")}</ModalHeader>
        <ModalBody>
          {selectedNotification ? (
            <div>
              <h6>{selectedNotification.title}</h6>
              <p>{selectedNotification.content}</p>
              <p className="text-muted">
                <i className="mdi mdi-clock-outline" /> {formatTimestamp(selectedNotification.$createdAt)}
              </p>
            </div>
          ) : (
            <SimpleBar style={{ height: "400px" }}>
              {sortedNotifications.map(notification => (
                <div key={notification.$id} className={`mb-2  ${!notification.seen ? "bg-light" : ""}`} style={notification.seen ? { padding: "1rem" } : notStyle}>
                  <h6>{notification.title}</h6>
                  <p>{notification.content}</p>
                  <p className="text-muted">
                    <i className="mdi mdi-clock-outline" /> {notification.$createdAt
                      ? new Date(notification.$createdAt).toLocaleString("ar-EG", {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric'
                      })
                      : 'Invalid date'}
                  </p>
                </div>
              ))}
            </SimpleBar>
          )}
        </ModalBody>
        <Button color="secondary" onClick={toggleModal}>إغلاق</Button>
      </Modal>
    </React.Fragment>
  );
};

const formatTimestamp = (timestamp) => {
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };

  return new Date(timestamp).toLocaleString(undefined, options);
};

NotificationDropdown.propTypes = {
  t: PropTypes.func.isRequired,
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      $id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
      notification_type: PropTypes.string.isRequired,
      seen: PropTypes.bool.isRequired,
      $createdAt: PropTypes.string.isRequired,
    })
  ),
};

export default withTranslation()(NotificationDropdown);
